
import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

// Storybook
import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";

import { useNotification } from "stories/components/Notification"

// App
import { round ,roundScaleDecimals, thousandFormat} from "services/helpers"

import OptionRightInput from "components/Forms/OptionRightInput/OptionRightInput"
import OptionAssetTypeInput from "components/Forms/OptionAssetTypeInput/OptionAssetTypeInput"
import OptionStateInput from "components/Forms/OptionStateInput/OptionStateInput"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import CommissionSelect from "components/Forms/CommissionSelect/CommissionSelect"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import BatchSelect from "components/Forms/BatchSelect/BatchSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"
import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"
import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"

import HelpText from "components/Info/HelpText.js"


import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import { httpGetStockData } from "services/stocks"

import { httpGetPrivateStockDatas } from "services/private_stocks"
import { 
  httpGetTransaction, 
  httpGetTransactions, 
  httpCreateTransaction, 
  httpUpdateTransaction, 
  httpDeleteTransaction, 
  httpGetStockDateBalance, 
  httpCreatePrivateStockTransaction, 
  httpUpdatePrivateStockTransaction,
  httpDeletePrivateStockTransaction,
  httpGetPrivateStockDateBalance,
  httpGetPrivateStockTransaction,
  httpGetPrivateStockTransactions,
  httpGetCurrentBatchBalanceRatio
} from "services/transactions"

import { 
  httpGetPrivateInvestimentFundDatas
} from "services/private_investment_funds";

import {httpGetInvestmentFundData} from "services/investment_fund";


import { httpGetCurrencyRate } from "services/currencies"
import { set, startOfDay } from "date-fns";
import { de } from "date-fns/locale";

import TransactionsFileHandling from "components/Forms/TransactionsFileHandling/TransactionsFileHandling"
import {
  httpUploadFiles,
} from "services/files"
import UploadFile from "components/Forms/UploadFile/UploadFile"


let today_date = new Date()
today_date.setHours(12, 0, 0, 0);

const defaultValues = {
  id: null,
  // commission_total: "0",
  currency: "EUR",
  // price: "0",
  // price_total: "0",
  // quantity: "0",
  // date_transaction: moment().format("YYYY-MM-DD"),
  date_transaction: today_date,
  date_settlement: today_date,
  fifo_used: true,
  // date_settlement: moment().format("YYYY-MM-DD"),
}

const PurchaseEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     setFieldError,
     setFieldTouched,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues,
     objectId,
     setValues,
     setErrors,
      setTouched,
   } = props;
   
   const {
      t,
      history,
      currencyDate,
      setCurrencyDate,
      securityTargetRate,
      setSecurityTargetRate,
      portfolioSourceRate,
      setPortfolioSourceRate,
      securitySourceRateDate,
      setSecuritySourceRateDate,
      stocks,
      stockMarket,
      setStockMarket,
      setInvestmentType,
      investmentType,
      fundType,
      setFundType,
      setFilters,
      totalValue,
      setTotalValue,
      totalValueEur,
      setTotalValueEur,
      stocksLoading,
      setCurrentStock,
      setBatchs,
      batchs,
      portfolio,
      batchRatio,
      setBatchRatio,
      transaction_count,
      maxSaldo,
      setMaxSaldo,
      setBatchWac,
      batchWac,
      setFiles,
      files,
      handleFileUpload,
      trxFiles,
      setTrxFiles,
 } = props;
   
 const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  const account = useStoreState((state) => state.account?.account);
  
  const [stockDatePrice, setStockDatePrice] = useState();
  const [stockDatePriceLoading, setStockDatePriceLoading] = useState(false);
  const [batch , setBatch] = useState(null);
  const [isSelectOpen,setIsSelectOpen] = useState(false);
  const [firstTimeQuantity, setFirstTimeQuantity] = useState(false);
  
  const [stockTickerSymbol, setStockTickerSymbol] = useState(null);


  const [calculatePriceClicked, setCalculatePriceClicked] = useState(false);
  const [calculatePriceTotalClicked, setCalculatePriceTotalClicked] = useState(false);
  const [securityCurrencyChanged, setSecurityCurrencyChanged] = useState(false);
  const [dateTransactionChanged, setDateTransactionChanged] = useState(false);
  const [dateTransactionChangedFirstTime, setDateTransactionChangedFirstTime] = useState(true);
  
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateBasicDataObjects = useStoreActions((actions) => actions.privateStocks.getObjects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        if (stockMarket === "PRIVATE") {

          setErrors({
            ...res,
            stock_private_obj: res?.stock_private_obj?.id ? res?.stock_private_obj?.id : t("Kenttä on pakollinen"),
            general: t("Please check the form errors", "Tarkista lomakkeen virheet, Osake")
          })
        }
        else {
          setErrors({
            ...res,
            stock: res?.stock?.id ? res?.stock?.id : t("Kenttä on pakollinen"),
            general: t("Please check the form errors", "Tarkista lomakkeen virheet")
          })
        }
      }
    })
  }

  const findStockObject = useCallback ((id) => {
    // const filteredObjects = stocks && stocks.filter(o => o.id === id)
    // if (filteredObjects && filteredObjects.length > 0) {
    //   return filteredObjects[0]
    // }
    // return null
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }

    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }, [stocks, stocksLoading])


  useEffect(() => {
    if (portfolio.sell_profit_calculation_method=="WAC"){
      setFieldValue("fifo_used", false)
    }

    },[])

  useEffect(() => {
    let currency = portfolio?.currency_label


    // if (stockMarket == "XHEL"){
    //   currency = "EUR"
    // }
    // else if (stockMarket == "XSTO"){
    //   currency = "SEK"
    // }
    // else if (stockMarket == "XICE"){
    //   currency = "ISK"
    // }
    // else if (stockMarket == "XCSE"){
    //   currency = "DKK"
    // }

    // const stock = findStockObject(values?.stock?.id)
    // if (stock?.currency_label){
    //   currency = stock?.currency_label
    // }
    // else if (stock?.currency){
    //   currency = stock?.currency
    // }
    // else if (stock?.trading_currency){
    //   currency = stock?.trading_currency
    // }

    setFieldValue("currency", currency )
  }, [portfolio, values?.stock])

  //----------------------------UPDATE BASICDATA when stock market change----------------------------------------------------
  useEffect(() => {
    if (investmentType) {
      console.log("PRIVATE basic_data")
      // getPrivateBasicDataObjects()

      let param ={
        stock_market:stockMarket,
        related_model_type: investmentType,
        portfolio_batches_stock: portfolio?.id,
        show_hidden: true
      }

      if (investmentType == "30" ){
        param ={...param, type: fundType}
      }
      getBasicDataObjects(param)
    }
    else {
    getBasicDataObjects({stock_market:stockMarket, show_hidden: true})
    }
    // if (values)
    // setFieldValue('stock', null)
  }, [stockMarket, investmentType,fundType ])
  
  useEffect(() => {
    setFieldValue("batch_select", null)
    setBatchs([])
    setBatch(null)
    setBatchWac({
      batch_select_wac: []
    })
    if (!objectId){
      setFieldValue("stock", null)
    }
    setStockTickerSymbol(null)

  }, [portfolio])
  
  //----------------------------Show user that date settlement changed ----------------------------------------------------

    useEffect(() => {

      if (dateTransactionChanged && values?.date_transaction ) {
        setTimeout(() => {
          setDateTransactionChanged(false);
        }, 1000);
      }
      else if (dateTransactionChangedFirstTime) {
        setDateTransactionChangedFirstTime(false)
      }
    }, [dateTransactionChanged])

  //----------------------------UPDATE totalValue STATE----------------------------------------------------
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const commission_total = parseFloat(values?.commission_total ? values?.commission_total : 0)


    if (objectId){
      setTotalValue(round(price_total + commission_total, 2))
    }
    else{
      setTotalValue(round(price_total - commission_total, 2))
    }
    
  }, [values?.price_total, values?.commission_total])
  
  /* Optional: if you want to calc price reversed
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const quantity = parseInt(values?.quantity)
    let calculation = 0
    if (price_total && price_total > 0 && quantity && quantity > 0) {
      calculation = price_total / quantity
    }
    if (calculation && calculation != parseFloat(values?.price)) {
      setFieldValue("price", calculation)
    }
  }, [values?.price_total, values?.quantity])
  */
  

  //----------------------------UPDATE TOTAL PRICE Automatically ----------------------------------------------------


  const handleCalculatePriceTotal = () => {
    setCalculatePriceClicked(false)
    setCalculatePriceTotalClicked(true)
    const quantity = parseFloat(values?.quantity)
    const price = parseFloat(values?.price)
    let calculation = 0
    if (price && quantity && price > 0 && quantity > 0) {
      calculation = price*quantity;
    }
    if (calculation && calculation !== parseFloat(values?.price_total)) {
      setFieldValue("price_total", parseFloat(roundScaleDecimals(calculation, 6)))
    }
    setTimeout(() => {
      setCalculatePriceTotalClicked(false);
    }, 1000);
  }

  useEffect(() => {
    // handleCalculatePriceTotal()
  }, [values?.price, values?.quantity])

    //----------------------------Calculate PRICE based on value_total and quantity ----------------------------------------------------


    const handleCalculatePrice = () => {
      setCalculatePriceClicked(true)
      setCalculatePriceTotalClicked(false)
      const quantity = parseFloat(values?.quantity)
      const price_total = parseFloat(values?.price_total)
      let calculation = 0
      setTimeout(() => {
        setCalculatePriceClicked(false);
      }, 1000);
      if (price_total && quantity ) {
        calculation = price_total /quantity;
      }
      else {return}
      setFieldValue("price", parseFloat(roundScaleDecimals(calculation, 6)))
    }
  


  //----------------------------UPDATE STOCK PRICE  {values.stock.id}----------------------------------------------------

  const findObject = (id) => {
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }
    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }

  useEffect(() => {
    let stockObject = findObject( stockMarket === "PRIVATE"? values?.stock_private_obj :values?.stock?.id)

    let ticker_symbol = undefined
    let stock_market = values?.stock_market
    let nasdaq_id =values?.nasdaq_id; // "ACG1V";
    if (stockObject) {
      nasdaq_id = stockObject?.nasdaq_id
      stock_market = stockObject?.stock_market
      if (stockObject?.ticker_symbol){
        ticker_symbol = stockObject?.ticker_symbol
        setStockTickerSymbol(stockObject?.ticker_symbol)
      }
      else {
        setStockTickerSymbol(stockObject?.symbol)
      }
    }
    else {
      setStockTickerSymbol(null)
    }
    if ((values?.stock && values?.date_transaction  && firstTimeQuantity)|| (values?.stock_private_obj && values?.date_transaction)) {
      const stock_date = undefined; // moment("2021-12-28").toISOString().split('T')[0] +" 00:00"; // "2021-12-27";
      let params = {
        offset:0, 
        limit:1, 
        // stock_date: "2021-12-26", // this exact filter not working
        // ticker_symbol: ticker_symbol,
        nasdaq_id: nasdaq_id,
        stock_market: stock_market,
      }
      
      // Stock date

      if (stockMarket === "PRIVATE") {
        // params["stock_date__gte"] = moment.utc(values?.date_transaction).startOf('day').toDate()
        // console.log(params["stock_date__gte"])
        // params["stock_date__lte"] = moment(values?.date_transaction).add("days",1).toISOString().split('T')[0] +" 23:59"; 
        
        // params["private_stock"] = values?.stock_private_obj?.id

        // httpGetPrivateStockDatas(params).then(res => {
        //   console.log("httpGetPrivateStockData res",res)
        //   if (res?.data?.count > 0) {
        //     setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price, 2).toFixed(2)))
        //   }
        //   else {
        //     setStockDatePrice(null)
        //   }
        // })
      }
      else {
        params["stock_date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
        params["stock_date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 

        if (investmentType == "10" && ticker_symbol){
          params["ticker_symbol"] = null;
          setStockDatePriceLoading(true)
          httpGetStockData(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.ending_price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "20"){
          params["private_stock"] = values?.stock?.id;

          setStockDatePriceLoading(true)
          httpGetPrivateStockDatas(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "30"){
          params["private_investment_fund"] = values?.stock?.id;
          params["date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
          params["date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 
          setStockDatePriceLoading(true)
          httpGetPrivateInvestimentFundDatas(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }

        if (investmentType == "40"){
          params["investment_fund"] = values?.stock?.id;
          params["date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
          params["date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 
          setStockDatePriceLoading(true)
          httpGetInvestmentFundData(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }


        // let batchsParams = {
          
        //   stock_market: investmentType != 10? null: stockMarket,
        //   related_model_id: values?.stock?.id,
        //   related_model_type: investmentType,
        //   portfolio: portfolio?.id,  
        //   ordering: "date_transaction",
        // }

        // httpGetTransactions(batchsParams).then(res => {
        //   //filter res.data.results by batch_balance > 0

        //   //
        //   if (res?.data?.length > 0) {
        //     const filteredResults = res?.data?.filter(result => result.batch_balance > 0);
        //     setBatchs(filteredResults)
        //     setBatch(filteredResults[0])
        //     setFieldValue("batch_select", null)
        //   }
        //   else {
        //     setBatchs([])
        //     setBatch(null)
        //     setFieldValue("batch_select", null)

        //   }

        //   // setBatchs(res?.data?.results)
        //   // setCount(res?.data?.count)
  
        //   //   setLoading(false)
          
        // })

        // batchsParams["batch_select"] = values?._wac?.id
        // httpGetCurrentBatchBalanceRatio(batchsParams).then(res => {
        //   setBatchRatio(res?.data)
        // })

      }
    }
  }, [values?.stock, values?.stock_private_obj, values?.date_transaction, firstTimeQuantity])

    //Get stock date price only when stock date change or stock change but only ones/frist time when quantity change
    useEffect(() => {
      if (values?.quantity && firstTimeQuantity == false) {
        setFirstTimeQuantity(true)
      }
    }, [values?.quantity])

  useEffect(() => {

    if (values?.stock){

    let batchsParams = {
          
      stock_market: investmentType != 10? null: stockMarket,
      related_model_id: values?.stock?.id,
      related_model_type: investmentType,
      portfolio: portfolio?.id,  
      ordering: "date_transaction",
      type:2,
      wac_date_lte: values?.wac_date_lte ? moment(values?.wac_date_lte).set({hour: 12, minute: 0, second: 0, millisecond: 0}).format() : undefined,
      wac_date_gte: values?.wac_date_gte ? moment(values?.wac_date_gte).set({hour: 12, minute: 0, second: 0, millisecond: 0}).format() : undefined,
    }

    httpGetTransactions(batchsParams).then(res => {
      //filter res.data.results by batch_balance > 0

      //
      if (res?.data?.length > 0) {
        const filteredResults = res?.data?.filter(result => result.batch_balance > 0);
        setBatchs(filteredResults)
        setBatch(filteredResults[0])
        setBatchWac({
          batch_select_wac: []
        })
        !objectId && setFieldValue("batch_select", null)
      }
      else {
        setBatchs([])
        setBatch(null)
        setBatchWac({
          batch_select_wac: []
        })
        setFieldValue("batch_select", null)

      }

      // setBatchs(res?.data?.results)
      // setCount(res?.data?.count)

      //   setLoading(false)
      
    })

    batchsParams["batch_select"] = values?.batch_select?.id
    httpGetCurrentBatchBalanceRatio(batchsParams).then(res => {
      setBatchRatio(res?.data)
      setBatchWac({
        batch_select_wac: []
      })
    })
  }


  }, [values?.stock, values?.stock_private_obj, values?.wac_date_lte, values?.wac_date_gte])

  // useEffect(() => {
  //   let batchsParams = {
          
  //     stock_market: stockMarket,
  //     related_model_id: values?.stock?.id,
  //     related_model_type: investmentType,
  //     portfolio: portfolio?.id,  
  //     ordering: "date_transaction",
  //     _wac: values?._wac
  //   }
  //   if (values?._wac !== null) {
  //   httpGetCurrentBatchBalanceRatio(batchsParams).then(res => {
  //     console.log("httpGetCurrentBatchBalanceRatio res",res)
  //     if (res?.data) {
  //       setBatchRatio(res?.data?.ratio)
  //     }
  //     else{
  //       setBatchRatio(1)
  //     }
  //   })

  //   }
  // }, [values?._wac])



  useEffect(() => {
    if (values?.stock || values.stock_private_obj) {
      let stockObject = findObject(values?.stock?.id)
      if (stockObject) {
        if (stockMarket === "PRIVATE") {
          setFieldValue("stock_private_obj", {...values.stock_private_obj, label: stockObject?.name})
        }
        else {
        setFieldValue("stock", {...values.stock, label: stockObject?.name})
        
        }
      }
    }
  }, [stocks])


  //---------------------------UPDATE date_settlement if date_transaction are changed----------------------------------------------------
    useEffect(() => {
      if (!objectId){
      setFieldValue("date_settlement", values?.date_transaction)
      }
  }, [values?.date_transaction])

  

  
  //---------------------------- Update subscription BEGIN---------------------------------

  const openUpdateUp = useCallback(() => {
    return (<>{(account?.license?.limit_transactions <= transaction_count && objectId == null) && <UpdateUp redirectToFrontpage={false} forceOpen={account?.license?.limit_transactions <= transaction_count}/>}</>)
  }, [account?.license])

  // Update subscription END

  //------------------------UPDATE COMISSION TOTAL-----------------------------------------------
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const commission = parseInt(values?.commission)
    if (price_total && price_total > 0 && commission && commission > 0) {
      if (commission === 2) {
        setFieldValue("commission_total", 0)
      }
      else if (commission === 3) {
        const commission_total = 8.0
        setFieldValue("commission_total", round(commission_total,2))
      }
      else if (commission === 4) {
        const commission_total = round(8.0 + (0.025*price_total), 2)
        setFieldValue("commission_total", round(commission_total,2))
      }
      
    }
    else {
      // setFieldValue("commission_total", 0)
    }
  }, [values?.price_total, values?.commission])
  


  // ------------------UPDATE CURRENCY DATE-----------------------------------
  useEffect(() => {
    if (values?.currency && values?.date_transaction) {
      
      if (values?.currency === "EUR") {
        setPortfolioSourceRate(1.0)
      }
      else {
        let params = {
          offset:0, 
          limit:1, 
        }
        params["Date"] = moment(values?.date_transaction).format("YYYY-MM-DD")
        params['currency'] = values?.currency
        httpGetCurrencyRate(params).then(res => {
          if (res?.data?.currency_rate) {
            setPortfolioSourceRate(res?.data?.currency_rate)
          }
          else {
            setPortfolioSourceRate(1.0)
          }
        }, err => {
          setPortfolioSourceRate(null)
        })

      }
    }
    else {
      setPortfolioSourceRate(null)
    }
  }, [values?.currency, values?.date_transaction])

  useEffect(() => {
    if (securityTargetRate && portfolioSourceRate){

      setCurrencyDate(roundScaleDecimals(securityTargetRate/portfolioSourceRate, 3))
    }
    else{
      setCurrencyDate(null)
    }

  }, [securityTargetRate, portfolioSourceRate])
  


  // ------------------UPDATE TOTAL VALUE EUR-----------------------------------
  useEffect(() => {
    if (currencyDate && totalValue ) {
      setTotalValueEur(round(totalValue*currencyDate, 2))
    }
    else {
      setTotalValueEur(totalValue)
    }
      
      
  }, [currencyDate, totalValue])
  

  // -------------------------SET PRICE automatically if in field have no value already--------------------------------------------
  useEffect(() => {
    // if (!values.price) {
    //   handleUseStockDatePrice()
    // }
    // handleUseStockDatePrice()
  }, [stockDatePrice])
            



  // ------------------fUCTIONS TO USE-----------------------------------

  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleConfirm = () => {
    /*
    httpConfirmPurchaseOrderShipping(values?.id).then(res => {
      handleSubmit()
    })
    */
  }

  useEffect(() => {
      
    if (values?.stock && values?.date_transaction) {
      const security_currency = getStockCurrency()
      let params = {
        offset:0, 
        limit:1, 
        // ordering: '-Date'

      }
      params["Date"] = moment(values?.date_transaction).format("YYYY-MM-DD")
      params['currency'] = security_currency
      // if (security_currency === "EUR") {
      //   setSecurityTargetRate(1.0)
      // }
      // else{
        httpGetCurrencyRate(params).then(res => {
          if (res?.data?.currency_rate) {
            setSecurityTargetRate(res?.data?.currency_rate)
            setSecuritySourceRateDate(res?.data?.currency_date)

          }
          else {
            setSecurityTargetRate(1.0)
            setSecuritySourceRateDate(null)
          }
        }, error => {
          setSecurityTargetRate(null)
          setSecuritySourceRateDate(null)
        })
      }
    // }
    else {
      setSecurityTargetRate(null)
      setSecuritySourceRateDate(null)
    }
  }, [values?.stock, stocksLoading, values?.date_transaction])



  const getStockCurrency = useCallback(() => {
    const stock = findStockObject(values?.stock?.id)
    let currency = null
    if (stock?.currency_label){
      currency = stock?.currency_label
    }
    else if (stock?.currency){
      currency = stock?.currency
    }
    else if (stock?.trading_currency){
      currency = stock?.trading_currency
    }
    return currency
  }, [values?.stock, stocks, stocksLoading])



  const handleStockMarketChange = ({target}) => {
    setStockMarket(target.value)
    setFieldValue("stock", null)
    setFieldValue("stock_private_obj", null)
    setStockTickerSymbol(null)
    setStockDatePrice(null)
    setBatchs([])
    setBatch(null)
    setBatchWac({
      batch_select_wac: []
    })
    setFieldValue("batch_select", null)
    setBatchRatio([])


    if (target.value === "PRIVATE") {
      
      setErrors({
        ...errors,
        stock_private_obj: t("Kenttä on pakollinen"),
      })
    }
    else {
      setErrors({
        ...errors,
        stock: t("Kenttä on pakollinen"),
      })
    }

  }

  const handleBatchChange = ({target}) => {
    setBatch(target.value)
    setFieldValue("batch_select", target.value)

  }

  const handleBatchMultiChange = (name, value) => {
    setBatchWac(s => ({
      ...s,
      [name]: value
  }))
  }

  const handleFundChange = ({target}) => {
    setFundType(target.value)   
    setFieldValue("stock", null)
  } 


  const handleInvestmentTypeChange = ({target}) => {
    if (target.value === "10") {
      setStockMarket("XHEL")
      // setFilters(s => ({
      //   ...s,
      //   related_model_type: "10",
      // }))
      setInvestmentType(target.value)   
      setFieldValue("stock", null)
      setFieldValue("stock_private_obj", null)
      setStockTickerSymbol(null)
      setStockDatePrice(null)
      setFieldValue("batch_select", null)

      setBatchs([])
      setBatch(null)
      setBatchWac({
        batch_select_wac: []
      })
      setBatchRatio([])

    }
    else {
      setStockMarket(null)
      setInvestmentType(target.value)
      // setFilters(s => ({
      //   ...s,
      //   // related_model_type: target.value,
      //   // stock_market: null,
      // }))
      setFieldValue("stock", null)
      setFieldValue("stock_private_obj", null)
      setFieldValue("batch_select", null)
      setStockTickerSymbol(null)
      setStockDatePrice(null)
      setBatchs([])
      setBatch(null)
      setBatchWac({
        batch_select_wac: []
      })
      setBatchRatio([])
    }
  } 

  const getBatchObj = useCallback ((id) => {
    //loop all batchRatio and find the one with the same id
    // console.log("batchRatio", batchRatio)
    let balance = null;
    if (batchRatio?.[0]?.id)  {
      // console.log("batch------")
      batchRatio && batchRatio.map(obj => {
      if (obj.id == id) {
        balance = obj.batch_balance;
      }
    })  
    }
    // console.log(ratio)
    return balance;
  }, [batchRatio, batchWac])
  
  const getBatchesBalance = useCallback ((id) => {
    //loop all batchRatio and find the one with the same id
    // console.log("batchRatio", batchRatio)
    let sumBalance = 0;
    if (batchRatio?.[0])  {
      if (batchWac?.batch_select_wac?.length > 0) {
        batchWac?.batch_select_wac?.map(obj => {
          sumBalance = sumBalance + getBatchObj(obj?.id)
        })
      }
      else {
        batchRatio.map(obj => {
          //  let currentBalance = getBatchObj(obj.id) 
          sumBalance = sumBalance + obj?.batch_balance;
          // console.log(sumBalance)
        })
      }

    }
    // console.log(sumBalance)
    //convert to 2 decimals
    // sumBalance = Number(sumBalance.toFixed(0))
    // return 34
    setMaxSaldo(roundScaleDecimals(sumBalance,6))
    return sumBalance;
  }, [batchRatio, batchs, batchWac])

  const getDefaultValues = useCallback(() => {
    // if (location?.state?.stockId && !values?.private_stock && !values?.stock==="stock_market_changed") {
    //   return {value: location.state?.stockId, label: location.state?.stockName}
    // }
    if (values?.stock_market === "PRIVATE") {
    return {value: values?.private_stock, label: values?.private_stock_name }
    }
    return {value: values?.stock, label: values?.stock_name }
  }, [values])

  
  const handleUseStockDatePrice = () => {
    if (stockDatePrice && !objectId) {
      setFieldValue("price", Number(stockDatePrice.toFixed(2)))
    }
  }


  
  const handleDelete = () => {

    if (stockMarket === "PRIVATE") {
      httpDeletePrivateStockTransaction(values?.id).then(res => {
        props.notify({ title:t("Kirjaus"), message:t("Poistettu")})
        props.handleResolve()
      }, error => {
        
        props.notify({ title:t("Kirjaus"), message:t("Poisto epäonnistui")})
      })
    }
    else {
      httpDeleteTransaction(values?.id).then(res => {
        props.notify({ title:t("Kirjaus"), message:t("Poistettu")})
        if (investmentType == "10"){
          setFilters(s => ({
              ...s,
              stock_market: null,
              related_model_type: null,
            }))
      }
      else {
          setFilters(s => ({

              ...s,
              stock_market: null,
              related_model_type: null,
            }))
      }
        props.handleResolve()
      }, error => {
        console.log("httpCreateStockTransaction error", error);
        props.notify({ title:t("Kirjaus"), message:t("Poisto epäonnistui")})

        if (error?.status === 400) {
          setErrors(error?.data)
        }
        else {
          setErrors({
            general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
        
      })
    }
  }

  const onChangeBatch = ({target}, child) => {
    if (target?.value > 0) {
      const filtered = batchs.filter(o => o.id == target?.value)
      if (filtered && filtered.length > 0) {
        setBatch(filtered[0])
      }
    }
    if (target?.value === ""){
      setBatch("")
    }
  }

  const getBatch = useCallback((id) => {
    // return batchs.find(o => o.id === id)
    id = Number(id)
    return batchs.find(o => o.id === id)
  }, [values.batch_select])


  


  const getStockSelect = useCallback(() => {
    if (stockMarket === "PRIVATE") {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("stock_private_obj")}
          label={t("sale_order_edit_asset_stock", "Osake (yksityinen)")}
          setFieldValue={setFieldValue}
          setValueToSelect={true}
          ratesView={false}
          setFieldValueReturnId={false}

        />
      )
    }
    else {
      return (
        <div name="stock_select_click">
        
        <StockSelect
          {...getFormikInputProps("stock")}
          label={t("purchase_order_edit_asset_stock-", "Arvopaperi *")}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          loading={stocksLoading}
          setValues={setValues}
          setFieldValueReturnId={false}
          ratesView={false}
          setValueToSelect={true} 
          disabled={objectId?true:false}
          setIsSelectOpen={setIsSelectOpen}
          isSelectOpen={isSelectOpen}
          id="stock"
          async={true}
        />
        </div>
      )
    }
  }, [investmentType, stockMarket, values?.stock, values.stock_private_obj, errors?.stock, errors?.stock_private_obj, errors,])


  //----------------------------Show user that security currency changed ----------------------------------------------------



  useEffect(() => {
    if (getStockCurrency() !== values?.currency && !securityCurrencyChanged && values?.stock) {
      setSecurityCurrencyChanged(true)
      setTimeout(() => {
        setSecurityCurrencyChanged(false);
      }, 1000);
    }
    setBatchWac({
      batch_select_wac: []
    })
  }, [values?.stock])


  const getBatchSelect = useCallback(() => {
    let placeholder = t("Kaikki")
    if (values?.wac_date_gte && values?.wac_date_lte) {
      placeholder = t("Kaikki erät valitulta ajalta")
    }
    if (values?.wac_date_gte && !values?.wac_date_lte) {
      placeholder = ` ${t("Erät alkaen")} ${moment(values?.wac_date_gte).format('DD.MM.YYYY')} ` 
    }
    if (!values?.wac_date_gte && values?.wac_date_lte) {
      placeholder = ` ${t("Erät päättyen")} ${moment(values?.wac_date_lte).format('DD.MM.YYYY')} ` 
    }
    
    return <BatchSelect 
      name="batch_select_wac" 
      disabled={objectId?true:false} 
      label=" " 
      placeholder={placeholder} 
      multiple={true}  
      onChange={handleBatchMultiChange} 
      setFieldValue={handleBatchMultiChange} 
      value={batchWac?.batch_select_wac}  
      batchs={batchs} 
      batchRatio={batchRatio} 
      showPrivate={false} 
      showAll={false} 
      icon=""
      />
  }, [batchs, batchRatio, batchWac, investmentType, stockMarket, values?.stock, values.stock_private_obj, values?.wac_date_gte, values?.wac_date_lte])


  
  return (
    <>

      {/*JSON.stringify(values)}
      {"---------------------"}
      {JSON.stringify(errors)*/}
      {/* {JSON.stringify(batchRatio)} */}

    
    
    {openUpdateUp()}
    
    <div className="px-3">


      <Row className="mt-4 mb-1 border-bottom ">
        <Col xs={12} md={6} className="ml-auto pb-3">

          {objectId ? (
            <Typography variant="h2"  >{t("sale_order_edit_title", "Muokkaa myyntitapahtumaa")}</Typography>
          ) : (
            <div className="d-inline-flex">

            <Typography variant="h2">{t("sale_order_add_title", "Lisää myynti salkkuun")}: </Typography>
            <Typography variant="h2"  className="font-weight-light ml-1"> {portfolio?.name}</Typography>
            </div>
          )}
        </Col>
        <Col xs={12} md={6} className="ml-auto pb-3">
        {/* <PortfolioSelectStore icon="fas fa-suitcase" iconColor="text-secondary" disabled={true}/> */}
        </Col>
      </Row>
      <Row  className="py-3 ">
        <Col xs={12} md={6} className="border-right px-4 pt-0">
          <InvestmentTypeSelect name="related_model_type1" onChange={handleInvestmentTypeChange} value={investmentType} disabled={objectId?true:false} showAll={false}/>
          {
            investmentType === 30 || investmentType === "30" ? (
            <Row className="mt-3">
              <Col>
                <InvestmentTypeSelectSub name="fund_type" onChange={handleFundChange} value={fundType} showPrivate={false} showAll={false} icon=""  disabled={objectId? true: false}/>
              </Col>
            </Row>
            ) : null
          }
          {
            investmentType === 10 || investmentType === "10" ? (
            <Row className="mt-3 ">
              <Col>
                <StockMarketSelect name="stock_market" onChange={handleStockMarketChange} value={stockMarket} disabled={objectId?true:false} showPrivate={false} showAll={false} icon=""/>
              </Col>
            </Row>
            ) : null
          }
          <Row>
            <Col className="mt-2">
             
            { getStockSelect() }

            { stockTickerSymbol ? (
              <div className="d-flex">
                <Typography variant="small" className="mr-auto pl-1">{t("Kaupankäyntitunnus")}: {stockTickerSymbol}</Typography>
                <Typography variant="small" style={{fontWeight: securityCurrencyChanged ? "normal" : "normal"}} className={securityCurrencyChanged && objectId == null ? "text-secondary pr-1" : "pr-1"}> {t('Valuutta')}: <span >{getStockCurrency()}</span></Typography>
              </div>
            ) : null }
            </Col>
          
          </Row>
          {
            portfolio.sell_profit_calculation_method =="FIFO" && (
              <>
              <Row className="mb-1">
              <Col  className="my-3">
              <div style={{position: 'absolute', zIndex: "0 !important"}}>
              <Checkbox 
              id="fifo"
              name="fifo_used"
              checked={values?.fifo_used}
              onChange={(e) => {setFieldValue("fifo_used",e.target.checked)}}
              value="agree_terms"
              required
              errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
              label={<>{t("sale_fifo_used",'FIFO käytössä')}</>}
              labelFirst={true}
              className="zIndexZero"
              />
              </div>
  
              </Col>
            </Row>
            <>
            {values?.fifo_used ? (
              <Row>
                  
                <Col className="mb-3 mt-2">
                  <Typography className="" variant="small">
                    {t("sale_fifo_info_text","Myynti eristä toteutetaan FIFO periaatteella, vanhimmat myydään ensin.")}
                  </Typography>
                </Col>
              </Row>
              ) : (
                <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <label className="mb-1 pr-1 header">{t("sale_edit_batch_select", "Hankintaerä")}</label>
                </Col>
                <Col className=" text-warning">
                  <BatchSelect  disabled={objectId?true:false} label=" "  {...getFormikInputProps("batch_select")} onChange={handleBatchChange} value={batch}  batchs={batchs} batchRatio={batchRatio} showPrivate={false} showAll={false} icon=""/>
                </Col>
              </Row>
              )}
            </>
      
            </>
            )


          }
          


          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-1 text-right">
              {stockMarket === "PRIVATE" ? (
                <Typography className="text-secondary" variant="small">
                {values.stock_private_obj === undefined || 
                  values.stock_private_obj === null || 
                  stockDatePrice ? null : t("Kurssin päätöshintaa Myyntipäivälle ei ole saatavilla ")}
                </Typography>

                ):(
                <Typography className="text-secondary" variant="small">
                {values.stock === undefined || 
                  values.stock === null || 
                  stockDatePrice ? null : t("Kurssin päätöshintaa myyntipäivälle ei ole saatavilla ")}
                </Typography>

              )}  
              </Col>
          </Row> */}
          
          <Row className="mb-3 mt-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("sale_edit_date_transaction", "Myyntipäivä")} *</label>
            </Col>
            <Col className="mb-1">
              <DatePicker
                className="form-control"
                selected={values?.date_transaction}
                name="date_transaction"
                onChange={(date) => {
                  setFieldValue("date_transaction", date)
                  setDateTransactionChanged(true)
                  
                }}
                showYearDropdown
                dateFormat="d.M.yyyy"
                placeholderText="15.1.2000"
                value={values?.date_transaction}
                disabled={objectId? true: false}
                // showTimeSelect
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t(moment(values?.date_transaction).format("dddd"))}/>}

                // timeIntervals={1}
                // timeCaption="Time"
              />

              {/*
              <Input
                {...getFormikInputProps("date_transaction")}
                label={t("sale_edit_date_transaction", "Myyntipäivä")}
                type="date"
              />
              */}

          </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("Myyntiaika")} *</label>
              <div> </div>
              <HelpText contentKey="transaction_edit_date_transaction_time" parentElementId="date_transaction_time" showButton={true}  />
            </Col>
            <Col className="mb-1">

                <DatePicker
                  className="form-control "
                  selected={values?.date_transaction}
                  name="date_transaction"
                  onChange={(date) => setFieldValue("date_transaction", date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="12:00"
                  customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t("klo")}/>}




                  // other props...
                />
            
            </Col>
          </Row>

          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3 d-flex">
            <Typography variant="small">{moment(values?.date_transaction).format("dddd")}</Typography>
            <Typography variant="small" className="ml-auto text-warning" >{t("syötä myös kellon aika")}</Typography>
            </Col>
          </Row> */}
          
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("settlement_date", "Selvityspäivä")} *</label>
              <div> </div>
              <HelpText contentKey="transaction_edit_date_settlement" parentElementId="date_settlement_helptext" showButton={true}  className="mb-0"/>
            </Col>
            <Col className="mb-1">
              <DatePicker
                className={`form-control b-0 calculated ${dateTransactionChanged ? "border-yellow" : ""}`}
                selected={values?.date_settlement}
                name="date_settlement"
                onChange={(date) => setFieldValue("date_settlement", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.date_settlement}
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={values?.date_settlement ? (moment(values?.date_settlement).format("dddd")):""}/>}
                disabled={objectId? true: false}

              />
              {/*
                <Input
                  {...getFormikInputProps("date_settlement")}
                  label={t("purchase_edit_date_settlement", "Selvityspäivä")}
                  type="date"
                />
              */}
              
            </Col>
          </Row>
          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3">
            <Typography variant="small">{moment(values?.date_settlement).format("dddd")}</Typography>
            </Col>
          </Row> */}

        <Row className="mb-1">
                    <Col sm={12} lg={4} className="d-flex align-items-center">
                      <label className="mb-0 pr-1 header">{t("broker", "Välittäjä")}</label>
                    </Col>
                    <Col className="mb-0">
                      <Input
                        {...getFormikInputProps("broker_name")}
                        placeholder={t("broker_placeholder", "Esim. Nordea")}
                        // icon="fas fa-user"
                      />
                    </Col>
          </Row>
        {
          portfolio.sell_profit_calculation_method == "WAC" && (
            <>

              <Row className="mb-2">
                  <Col  className="my-3">
                  <div style={{position: 'absolute', zIndex: "0 !important"}}>
                  <Checkbox 
                  id="WAC"
                  name="wac_used"
                  checked={values?.wac_used}
                  onChange={(e) => {setFieldValue("wac_used",e.target.checked)}}
                  value="agree_terms"
                  required
                  errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
                  label={<>{t("sale_wac_used",'Rajaa huomioitavia eriä')}</>}
                  labelFirst={true}
                  className="zIndexZero"
                  />
                  </div>
      
                  </Col>
              </Row>
              {values?.wac_used ? (
                <>
              <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <label className="mb-1 pr-1 header">{t("Erät alkaen pvm")} </label>
                  <div> </div>
                  {/* <HelpText contentKey="transaction_edit_date_transaction_time" parentElementId="date_transaction_time" showButton={true}  /> */}
                </Col>
                <Col className="mb-1">
                  <DatePicker
                      className={`form-control b-0 calculated `}
                      // selected={values?.wac_date_gte}
                      selected={values?.wac_date_gte ? new Date(values?.wac_date_gte).setHours(12, 0, 0, 0) : null} // Set time to 12:00
                      //set some placeholder value
                      placeholderText={t("Ei rajausta")}
                      name="wac_date_gte"
                      onChange={(date) => setFieldValue("wac_date_gte", date)}
                      showYearDropdown
                      dateFormat="d.M.yyyy"
                      value={values?.wac_date_gte ? new Date(values?.wac_date_gte).setHours(12, 0, 0, 0) : null}
                      customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={values?.wac_date_gte ? (moment(values?.wac_date_gte).format("dddd")):""}/>}
                      disabled={objectId? true: false}

                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <label className="mb-1 pr-1 header">{t("Erät päättyen pvm")} </label>
                  <div> </div>
                  {/* <HelpText contentKey="transaction_edit_date_transaction_time" parentElementId="date_transaction_time" showButton={true}  /> */}
                </Col>
                <Col className="mb-1">
                  <DatePicker
                      className={`form-control b-0 calculated`}
                      // selected={values?.wac_date_lte}
                      selected={values?.wac_date_lte ? new Date(values?.wac_date_lte).setHours(12, 0, 0, 0) : null} // Set time to 12:00
                      name="wac_date_lte"
                      onChange={(date) => setFieldValue("wac_date_lte", date)}
                      showYearDropdown
                      placeholderText={t("Ei rajausta")}
                      dateFormat="d.M.yyyy"
                      value={values?.wac_date_lte ? new Date(values?.wac_date_lte).setHours(12, 0, 0, 0) : null}
                      customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={values?.wac_date_lte ? (moment(values?.wac_date_lte).format("dddd")):""}/>}
                      disabled={objectId? true: false}

                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <label className="mb-1 pr-1 header">{t("sale_edit_batch_select_wac", "Valitut hankintaerät")}</label>
                </Col>
                <Col className=" text-warning">
                  {getBatchSelect()}
                  {/* <BatchSelect  disabled={objectId?true:false} label=" " multiple={true} {...getFormikInputProps("batch_select_wac")} onChange={handleBatchMultiChange} setFieldValue={handleBatchMultiChange} value={batch?.batch_select_wac}  batchs={batchs} batchRatio={batchRatio} showPrivate={false} showAll={false} icon=""/> */}
                </Col>
              </Row>
              </>
              ) : null
            }
          </>
          )
        }

          


          
          {/* <Row className="row justify-content-start mt-5">
            <Col lg={12} md={12} className="mb-1 text-left">
                <Typography className="text-red" variant="medium">
                  {t("HUOM! Muista tehdä salkun sisällä samaa osaketta koskevat kirjaukset aina kronologisessa järjestyksessä.")}
                </Typography>
                {objectId &&  
                  <Typography className="text-red" variant="medium">
                    {t("Mikäli tarve poistaa virheellinen kirjaus salkusta, tulee myös virheellistä kirjausta uudemmat samaa osaketta koskevat kirjaukset poistaa. Tämän jälkeen kaikki poistetut kirjaukset tulee luoda uudelleen.")}
                  </Typography>
                }
              </Col>
          </Row> */}

        </Col>
        <Col className="px-4 pt-0">
          <Row>
            <Col className="mb-0">
               <label className="mb-1 pr-1 header-bold">{t("sale_information", "Maksutiedot")}</label>
            </Col>
          </Row>
          {/* <Row >
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("purchase_order_edit_currency", "Valuutta")}</label>
            </Col>
            <Col className="mb-1">
              <CurrencySelect {...getFormikInputProps("currency")} label="" disabled={true}/>
              
              
            </Col>
          </Row>
          <Row className="row justify-content-end mb-3">
            {securityTargetRate && portfolioSourceRate && 
                <Col lg={8} md={12} >
                  <Typography variant="small">{t(`Valuuttakurssi`)}: {currencyDate ? currencyDate : t("Ei saatavilla")} {securitySourceRateDate && <span className="text-gray">  ({securitySourceRateDate})</span>}</Typography>
                </Col>
            }
          </Row>
           */}
          {/* 
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_commission", "Palkkio")}</label>
            </Col>
            <Col className="mb-0">
              <CommissionSelect
                {...getFormikInputProps("commission")}
                allOption={false}
                label=""
                disabled= {objectId? true : false}
              />
            </Col>
          </Row> */}
          
          <Row className="mb-3">
          
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_quantity", "Kappalemäärä")} *</label>
            </Col>
            <Col sm={12} lg={4} >
              

                <Input
                  {...getFormikInputProps("quantity")}
                  label=""
                  type="number"
                  min={0}
                  addonTypeClass="append"
                  iconColor={"bg-lighter"}
                  inputText={t("kpl")}
                  step={investmentType == 10 || investmentType == 20?"1":"0.01" }
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("")}
                  disabled= {objectId? true : false}
                  className={`calculated ${calculatePriceTotalClicked || calculatePriceClicked ? "border-yellow" : ""}`}
                />


            </Col>
            {values.batch_select && !values.fifo_used &&(
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <Typography variant="small">{getBatchObj(values.batch_select) } {t("sale_edit_batch_balance", " saatavilla")}</Typography>
              </Col>
            )}
            {(values.fifo_used || portfolio.sell_profit_calculation_method=="WAC") && values.stock && (
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <Typography variant="small">{roundScaleDecimals(getBatchesBalance(), 6)} {t("sale_edit_batch_balance"," saatavilla")}</Typography>
              
              </Col>
            )}
            
          </Row>
          
          <Row className="mb-1">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_price", "Kappalehinta")} *</label>
            </Col>
            <Col sm={12} lg={4} >
              <Input
                {...getFormikInputProps("price")}
                label=""
                type="number"
                step="0.01"
                min={0}
                onWheel={(e) => e.target.blur()}
                placeholder={t("")}
                addonTypeClass="append"
                iconColor={"bg-lighter"}
                inputText={values?.currency}
                className={`calculated ${calculatePriceTotalClicked ? "border-yellow" : ""}`}
                disabled= {objectId? true : false}

              />

            </Col>
            <Col className="text-left  mt-sm-2  mt-md-0">
              <Button variant="default" size="sm"  disabled={objectId? true: false} onClick={() => handleCalculatePrice()} className="">
                <Typography variant="medium" className="text-dark">{ t("transaction_edit_calculate_price", "Laske kpl hinta") }</Typography>
                
              </Button>
            </Col>
          </Row>
          {values?.quantity && values?.stock ?

              <Row className="row justify-content-end">
                <Col lg={8} md={12} className="mb-0">
                  <Typography variant="small">
                    {t("Myyntipäivän päätöshinta")}:  {
                          stockDatePriceLoading ? 
                            <Spinner animation="border" size="xs" className="spinner-height-15"/> 
                            : stockDatePrice ? 
                              <><span >{roundScaleDecimals(stockDatePrice / currencyDate,2)} {t(values?.currency)}</span> {getStockCurrency() != values?.currency ? <span className="text-gray">({stockDatePrice} {getStockCurrency() })</span> : null}</>
                              : t("Ei saatavilla")}
                    </Typography>
                </Col>
              </Row>
              : null
          }
            {/*
                <Row>
                <Col className="mb-3">
                <Button size="sm" onClick={handleUseStockDatePrice} variant="default">{t("Käytä")}</Button>
                <Button size="sm" onClick={handleCalculatePriceTotal} variant="default">{t("Laske")}</Button>
              </Col>
            </Row>
          */}
          
          
          <Row className="my-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_price_total", "Erän hinta")} *</label>
            </Col>
            <Col sm={12} lg={4} className="mb-0">
              <Input
                {...getFormikInputProps("price_total")}
                label=""
                type="number"
                step="0.01"
                min={0}
                // style={{ backgroundColor: "green", color: "white" }}
                onWheel={(e) => e.target.blur()}
                placeholder={t("")}
                className={`calculated ${calculatePriceClicked ? "border-yellow" : ""}`}
                disabled= {objectId? true : false}
                addonTypeClass="append"
                iconColor={"bg-lighter"}
                inputText={values?.currency}
              />
            </Col>
            <Col className="text-left  mt-sm-2  mt-md-0">
              <Button name="calculate_price_total" variant="default" size="sm"  disabled={objectId? true: false} onClick={() => handleCalculatePriceTotal()}>
                
                <Typography variant="medium" className="text-dark">{ t("Laske eränhinta") }</Typography>
              </Button>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_commission_total", "Kulut")}</label>
            </Col>
            <Col sm={12} lg={4} >
              <Input
                {...getFormikInputProps("commission_total")}
                label=""
                type="number"
                step="0.01"
                min={0}
                // style={{ backgroundColor: "red", color: "white" }}
                onWheel={(e) => e.target.blur()}
                placeholder={t("")}
                disabled= {objectId? true : false}
                addonTypeClass="append"
                iconColor={"bg-lighter"}
                inputText={values?.currency}
              />
            </Col>
          </Row>
          <Row className="row justify-content-end">
             <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-1 pr-1 header">{t("transaction_edit_value_total", "Hinta yhteensä")}</label>
            </Col>
            <Col lg={8} md={12} className="mb-3">
             <Typography variant="small" className={totalValue > 0?"text-dark":totalValue < 0?"text-dark": null}> {totalValue ? thousandFormat(totalValue) : 0.0} ({values?.currency})</Typography>
            </Col>
          </Row>
          

          { values.currency != getStockCurrency() && values?.stock != null  ? (
          <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3">
              <div className="d-flex ">
                <Typography variant="small" className={totalValue > 0?"text-dark":totalValue < 0?"text-dark": null}> {totalValueEur ? thousandFormat(totalValueEur) : 0.0} ({getStockCurrency()})</Typography>
                <Typography variant="small" className="ml-2"><span className="text-gray">{t("transaction_edit_rate",`Kurssi`)}: {currencyDate ? currencyDate : t("Ei saatavilla")} {securitySourceRateDate && <span className="text-gray">  ({securitySourceRateDate})</span>}</span></Typography>
              </div>

            </Col>
          </Row>
          ) : null}

          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("reference_code", "Viite")}</label>
            </Col>
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("reference_code")}
                // placeholder={t("reference_code", "Viite")}
                
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_comments", "Kommentit")}</label>
            </Col>
            <Col >
              <Input
                {...getFormikInputProps("notes")}
                label=""
                // placeholder={t("transaction_edit_comments", "Kommentit")}
                type="textarea"
              />
            </Col>
          </Row>
          <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3 text-right">
              <Typography variant="small"  >{values?.notes && values?.notes.length || 0}/70</Typography>
            </Col>
          </Row>

          {account && account?.license?.name !== "Lite" && (
            <>
            <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex ">
              <label className="mb-0 mt-3 pr-1 header">{t("attachment_file", "Liite")}</label>
            </Col>
            <Col className="mb-0">
              <UploadFile apiUrl="https://your-server.com/upload" setFiles={setFiles} files={files} padding={15} />
            </Col>
            
          </Row>
            <Row className="mb-3">
              <Col sm={12} lg={4} className="d-flex ">
              </Col>
              <Col className="mb-0">
                <TransactionsFileHandling setFiles={setTrxFiles} files={trxFiles} transaction={values} setFieldValue={setFieldValue} values={values}/>
                {/* {JSON.stringify(values?.connect_files)} */}
              </Col>
              
            </Row>
            </>
            )}


          <Typography  variant="small" className="mt-2 text-gray">
            {t("purchase_edit_required","* Tähdellä merkityt ovat pakollisia")}
          </Typography>
        </Col>

      </Row>
      
      <Row>
        <Col>{/*JSON.stringify(values)*/}</Col>
      </Row>
      <Row className="  bg-lighter pt-3  rounded-bottom">
        <Col className="mb-3">
          <Button name='saleEdit_cancel' onClick={handleCancel} variant="primary">{t("Sulje")}</Button>
        </Col>
        { values?.id > 0 ? (
        <Col className="mb-3 text-center">
          <Button name='transactionEdit_delete' onClick={handleDelete} variant="danger">{t("Poista")}</Button>
        </Col>
        ) : null}
        <Col className="mb-3 text-right">
          { !preSetValues?.state_delivery && (
            <Button name='saleEdit_submit' variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              { t("Kirjaa ja tallenna") }
            </Button>
          )}
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
      </div>
    </>
  );
}

const PurchaseEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {  
      const {t, stockMarket, investmentType, objectId, maxSaldo} = props;

      const required = t("Kenttä on pakollinen");
      if (stockMarket === "PRIVATE") {
        return Yup.object().shape({
          //checht that stock are object with id
          stock_private_obj: Yup.object().shape({
            id: Yup.number().nullable().required(required),
          }).nullable().required(required),
          quantity: Yup.number().required(required).nullable(),
          price : Yup.string().required(required).nullable(),
        });
      }
      else {

        return Yup.object().shape({
          //checht that stock are object with id
          stock: Yup.object().shape({
            id: Yup.number().nullable().required(required),
          }).nullable().required(required),
          // quantity: investmentType <=20?Yup.number().integer().min(0, t('Arvon tulee olla vähintään 0')).required(t('Arvo on pakollinen')): Yup.number().min(0.0000000000000, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
          quantity: investmentType <=20?Yup.number().integer().min(0, t('Arvon tulee olla vähintään 0')).required(t('Arvo on pakollinen')).test(
            "quantity-check",
            t("Määrä ei voi ylittää saldoa"),
            function(value) {
              const batchesBalance = maxSaldo;
              if (objectId) {
                return true;
              }
              return value <= batchesBalance;
            }
          ): Yup.number().min(0.0000000000000, t('Arvon tulee olla enemmän kuin 0')).required(required).test(
            "quantity-check",
            t("Määrä ei voi ylittää saldoa"),
            function(value) {
              const batchesBalance = maxSaldo;
              if (objectId) {
                return true;
              }
              return value <= batchesBalance;
            }
          ).nullable(),
          price: Yup.string().min(0.000000000000, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
          
          // price: Yup.string().test(
          //   "not-0",
          //   t("Value cannot be '0'"),
          //   (value) => value !== "0"
          // ),
          price_total: Yup.number().min(0.0000000000000, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
          date_transaction: Yup.date().required(required).nullable(),
          // value_total: Yup.number().notOneOf([0], t("Arvo ei voi olla 0")).required(required).nullable()
        });
      }
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, currencyDate, portfolio, investmentType, setFilters, stockMarket, totalValue, objectId, batchWac, handleFileUpload, files} = props;

      let data = {}

      if (stockMarket === "PRIVATE"){
        data = {
          ...values,
          commission_total: objectId ? values?.commission_total : -values?.commission_total || 0,
          currency_exchange_rate: currencyDate,
          currency_label: values?.currency,
          portfolio: portfolio?.id,
          price: values?.price,
          price_total: values?.price_total,
          stock: values?.stock_private_obj?.id,
          type: 3, // Sell
          date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
          date_transaction: values?.date_transaction ? moment(values?.date_transaction).format() : undefined,
          stock_market_label: stockMarket,
          // state: values?.state?.key,
          value_total: totalValue,
        };
      }         
      else {
        data = {
          ...values,
          commission_total:objectId ? values?.commission_total : values?.commission_total ? parseFloat(-values?.commission_total.toString().replace(",",".")) : 0,
          currency_exchange_rate: currencyDate,
          currency_label: values?.currency,
          portfolio: portfolio?.id,
          price: values?.price && parseFloat(values?.price.toString().replace(",",".")),
          price_total: values?.price_total && parseFloat(values?.price_total.toString().replace(",",".")),

          // stock: values?.stock_private_obj?.id,
          stock: values?.stock?.id,
          type: 3, // Sell
          date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
          date_transaction: values?.date_transaction ? moment(values?.date_transaction).format() : undefined,
          stock_market: stockMarket,
          related_model_id: values?.stock?.id,
          related_model_type: investmentType,
          fifo_used: values?.fifo_used? values?.fifo_used : false,
          // state: values?.state?.key,
          value_total: totalValue && parseFloat(totalValue.toString().replace(",",".")),
          reference_code: values?.reference_code,
          // wac_date_lte: values?.wac_date_lte ? moment(values?.wac_date_lte).format() : undefined,
          // wac_date_gte: values?.wac_date_gte ? moment(values?.wac_date_gte).format() : undefined,
          wac_date_lte: values?.wac_date_lte ? moment(values?.wac_date_lte).set({hour: 12, minute: 0, second: 0, millisecond: 0}).format() : undefined,
          wac_date_gte: values?.wac_date_gte ? moment(values?.wac_date_gte).set({hour: 12, minute: 0, second: 0, millisecond: 0}).format() : undefined,
          wac_ids: batchWac && batchWac?.batch_select_wac.map(item => item.id)
          
          //change wac_date_lte clock time to 12:00

        };

      }
      if (investmentType != "10"){
        data.ticker_symbol = null
        data.stock_market = null
      }
      if (values?.id) {
        if (stockMarket === "PRIVATE") {
          setSubmitting(true)
          httpUpdatePrivateStockTransaction (values?.id, data).then(res => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetty onnistuneesti")})

            props.handleResolve()
          }, error => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetys epäonnistui")})

            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }
        else {
          setSubmitting(true)
          //remove stock_market from data
          delete data.original_batch_balance
          delete data.batch_balance
          delete data.value_total
          delete data.stock_market
          delete data.price_total
          delete data.commission_total
          delete data.value_total
          delete data.price
          delete data.stock_market
          delete data.fifo_used
          delete data.wac_date_lte
          delete data.wac_date_gte
          delete data.wac_ids
          delete data.currency_exchange_rate
          delete data.currency_label
          delete data.date_settlement
          delete data.date_transaction
          httpUpdateTransaction(values?.id,data).then(res => {

            if(res?.data?.id){
              handleFileUpload([res?.data?.id], files)
            }
            props.notify({ title:t("Kirjaus"), message:t("Päivitetty onnistuneesti")})
            if (investmentType == "10"){
                setFilters(s => ({
                    ...s,
                    // stock_market: stockMarket,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            else {
                setFilters(s => ({

                    ...s,
                    // stock_market: null,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,

                  }))
            }
            props.handleResolve()
          }, error => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetys epäonnistui")})
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }
      }
      else {
        if (stockMarket === "PRIVATE") {
          setSubmitting(true)
          httpCreatePrivateStockTransaction(data).then(res => {
            props.notify({ title:t("Kirjaus"), message:t("Lisätty onnistuneesti")})

            props.handleResolve()
          }, error => {
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
  
        }
        else {
          // console.log(data.date_transaction)
          setSubmitting(true)
          // const oldDate = new Date(data.date_transaction);
          // const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), 12, 0, 0);
          // const formattedDate = newDate.toISOString().split('T')[0] +" 12:00:00Z";;
          // data.date_transaction = formattedDate;

          httpCreateTransaction(data).then(res => {
            if(res?.data?.id){
              handleFileUpload([res?.data?.id], files)
            }
            props.notify({ title:t("Kirjaus"), message:t("Lisätty onnistuneesti")})
            if (investmentType == "10"){
                setFilters(s => ({
                    ...s,
                    // stock_market: stockMarket,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            else {
                setFilters(s => ({

                    ...s,
                    // stock_market: null,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            props.handleResolve()
          }, error => {
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              // setErrors(error?.data)
              console.log("error?.data", error?.data)
              if (error?.data?.message) {
                props.notify({ title:t("Kirjaus"), message:error?.data?.message, type:'danger', autoDismiss:9})
              }
              else if (error?.data?.general) {
                props.notify({ title:t("Kirjaus"), message:error?.data?.general, type:'danger' , autoDismiss:9})
              }
              else {
                setErrors(error?.data)
              }
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }

      }

    },
    displayName: "BasicForm"
  
})(PurchaseEdit)
    
    
const SaleEditView = ({history, objectId,defaultStockMarket,defaultInvestmentType="10", ticker_symbol,transaction_count, setFilters, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(defaultValues);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [totalValue, setTotalValue] = useState(0);
  const [totalValueEur, setTotalValueEur] = useState(0);
  const [stockMarket, setStockMarket] = useState(defaultStockMarket? defaultStockMarket : "XHEL");
  const [investmentType, setInvestmentType] = useState(defaultInvestmentType? defaultInvestmentType : "10");

  const [currencyDate, setCurrencyDate] = useState(1.0);
  const [currentStock, setCurrentStock] = useState(null);
  const [batchs, setBatchs] = useState([]);
  const [batchRatio, setBatchRatio] = useState(1);
  const [securityTargetRate, setSecurityTargetRate] = useState(1.0);
  const [portfolioSourceRate, setPortfolioSourceRate] = useState(1.0);
  const [securitySourceRateDate, setSecuritySourceRateDate] = useState(null);
  const [maxSaldo, setMaxSaldo] = useState(0);
  
  // Store
  const options = useStoreState((state) => state.options?.options);
  const addOption = useStoreActions((actions) => actions.options.addOption);
  const stocks = useStoreState((state) => state.stocks?.objects);
  const stocksLoading = useStoreState((state) => state.stocks?.loading);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateBasicDataObjects = useStoreActions((actions) => actions.privateStocks.getObjects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  const [fundType, setFundType] = useState("private_stock");
  const [batchWac, setBatchWac] = useState({
    batch_select_wac: []
  })
  const [files, setFiles] = useState([]);
  const [trxFiles, setTrxFiles] = useState([]);
  
  const account = useStoreState((state) => state.account?.account);

  
  useEffect(() => {
    if (portfolio) {
      setPreSetValues(s => ({
        ...s,
        currency: portfolio?.currency_label
      }))
    }
  }, [portfolio])

  const handleFileUpload = (transactions) => {
    if (!files?.length) return
    const formData = new FormData();
    files.forEach(file => formData.append('file', file))
    // formData.append('files', files);
    formData.append('account', account?.id);
    formData.append('transactions', transactions)
  
    formData.append('portfolio', portfolio.id);
    // setLoadingSendExcel(true)
    httpUploadFiles(formData).then(res => {
      if (res?.data){
  
        console.log(res?.data)
      }
      setFiles([])
  
      // setLoadingSendExcel(false)
  
    }, error => {
      // setErrorText("Tapahtumien tuonti epäonnistui")
      if(error?.data?.message) {
        // setErrorMessage(t(error?.data?.message))
      }
      if(error?.data?.general) {
        //replace : with . to avoid rendering issues
        const general = error?.data?.general?.toString()
        const formattedError = general.replace(/:/g, '.')
        // setErrorMessage(t(formattedError))
      }
  
    }).finally(() => {
      // setLoadingSendExcel(false)
  
      // setLoadingImport(false)
    })
  
  
  };

  const findObject = useCallback ((id) => {
    // const filteredObjects = stocks && stocks.filter(o => o.id === id)
    // if (filteredObjects && filteredObjects.length > 0) {
    //   return filteredObjects[0]
    // }
    // return null

    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }
    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }, [stocks, stocksLoading])


  
  const getObject = (id) => {
    setLoading(true)

    if (stockMarket === "PRIVATE") {
      httpGetPrivateStockTransaction(id).then(res => {
        setStockMarket(res?.data?.stock_market_label)
  
        getPrivateBasicDataObjects({stock_market:res?.data?.stock_market_label})
        setPreSetValues({
          ...defaultValues,
          ...res.data,
          date_transaction: res?.data?.date_transaction ? moment(res?.data?.date_transaction).toDate() : new Date(),
          date_settlement: res?.data?.date_settlement ? moment(res?.data?.date_settlement).toDate() : new Date(),
          wac_date_gte: res?.data?.wac_date_gte ? moment(res?.data?.wac_date_gte).toDate() : null,
          wac_date_lte: res?.data?.wac_date_lte ? moment(res?.data?.wac_date_lte).toDate() : null,
          stock_private_obj:{id: res?.data?.stock, value:res?.data?.stock, label: findObject(res?.data?.stock)?.name},
  
          // price: round(res?.data?.price, 2) * -1,
          // value_total : round(res?.data?.value_total, 2) ,
          // price_total : round(res?.data?.price_total, 2) ,
          // commission_total : round(res?.data?.commission_total, 2) ,
          price: roundScaleDecimals(res?.data?.price, 6) ,
          value_total : roundScaleDecimals(res?.data?.value_total, 6) ,
          price_total : roundScaleDecimals(res?.data?.price_total, 6) ,
          commission_total : roundScaleDecimals(res?.data?.commission_total, 6) ,
          
        });
      
      }).finally(response => {
        setLoading(false);
      })
    }
    else{
      httpGetTransaction(id).then(res => {
        setStockMarket(res?.data?.stock_market)

        setTrxFiles(res?.data?.files)
  
        
        getBasicDataObjects({stock_market:res?.data?.stock_market, related_model_type: investmentType, show_hidden: true})

        setPreSetValues({
          ...defaultValues,
          ...res.data,
          date_transaction: res?.data?.date_transaction ? moment(res?.data?.date_transaction).toDate() : new Date(),
          date_settlement: res?.data?.date_settlement ? moment(res?.data?.date_settlement).toDate() : new Date(),
          wac_date_gte: res?.data?.wac_date_gte ? moment(res?.data?.wac_date_gte).toDate() : null,
          wac_date_lte: res?.data?.wac_date_lte ? moment(res?.data?.wac_date_lte).toDate() : null,
          stock:{id: res?.data?.related_model_id, value:res?.data?.related_model_id, label: findObject(res?.data?.related_model_id)?.name},

  
          price: roundScaleDecimals(res?.data?.price, 6) ,
          value_total : roundScaleDecimals(res?.data?.value_total, 6) ,
          price_total : roundScaleDecimals(res?.data?.price_total, 6) ,
          commission_total : roundScaleDecimals(res?.data?.commission_total, 6) ,
          ticker_symbol : ticker_symbol,
          
          

        });
      
      }).finally(response => {
        setLoading(false);
      })
    }


    
  }
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  if (loading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center">
        <Spinner className="mt-3" />
      </Container>
    )
  }

  return (
    <>

    <PurchaseEditFormik 
      t={t}
      history={history}
      preSetValues={preSetValues}
      notify={notify}
      objectId={objectId}
      addOption={addOption}
      currencyDate={currencyDate}
      setCurrencyDate={setCurrencyDate}
      portfolio={portfolio}
      stocks={stocks}
      stockMarket={stockMarket}
      setStockMarket={setStockMarket}
      totalValue={totalValue}
      setTotalValue={setTotalValue}
      totalValueEur={totalValueEur}
      setTotalValueEur={setTotalValueEur}
      stocksLoading={stocksLoading}
      currentStock={currentStock}
      findeObject={findObject}
      setInvestmentType={setInvestmentType}
      investmentType={investmentType}
      setFundType={setFundType}
      fundType={fundType}
      setFilters={setFilters}
      setBatchs = {setBatchs}
      batchs = {batchs}
      batchRatio = {batchRatio}
      setBatchRatio = {setBatchRatio}
      securityTargetRate={securityTargetRate}
      setSecurityTargetRate={setSecurityTargetRate}
      portfolioSourceRate={portfolioSourceRate}
      setPortfolioSourceRate={setPortfolioSourceRate}
      securitySourceRateDate={securitySourceRateDate}
      setSecuritySourceRateDate={setSecuritySourceRateDate}
      transaction_count={transaction_count}
      maxSaldo={maxSaldo}
      setMaxSaldo={setMaxSaldo}
      batchWac={batchWac}
      setBatchWac={setBatchWac}
      setFiles={setFiles}
      files={files}
      trxFiles={trxFiles}
      setTrxFiles={setTrxFiles}
      handleFileUpload={handleFileUpload}

      {...rest} 
    />
    </>
  )
  

}
  

export default SaleEditView;
