import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";

import { httpGetBasicData } from "services/stocks";
import { httpGetPrivateStocks } from "services/private_stocks";
import { httpGetPrivateInvestmentFunds } from "services/private_investment_funds";
import { httpGetInvestmentFunds } from "services/investment_fund";
import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"


import { Container, Row, Col, Card, CardHeader, CardBody, List } from "stories/layout";
import { Typography, Button, Link } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"

import HelpText from "components/Info/HelpText.js"


const ExchangeTo = (props) => {
  const {form, handleFormChange,exchangeSetFieldValue,showExtraField, handleFormRemove, index} = props
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState()
  const [fundType, setFundType] = useState("private_stock")
  const [show_hided_basicdata, setShowHidedBasicData] = useState(false);

  useEffect(() => {
    if (form.related_model_type == 10 && show_hided_basicdata == true) {
      getSecurities(form.related_model_type, form.stock_market)

    }
    else if (form.related_model_type == 10) {
      const stock_market_1 = form.stock_market ? form.stock_market : "XHEL"
      getSecurities(form.related_model_type, stock_market_1,)

    }
  }, [show_hided_basicdata])


  const handleInvestmentTypeChange = (index, event) => {
    if (event.target.value != form.related_model_type)
      getSecurities(event.target.value, "XHEL")
    handleFormChange(index, event)

  }

  const handleStockMarketChange = (index, event) => {
    if (event.target.value != form.stock_market)
      getSecurities(form.related_model_type, event.target.value )
    handleFormChange(index, event)

  }

  const setFieldValue = (name, value) => {
    

    if ((name == "related_model_type" && (value != form.related_model_type))) {
      getSecurities(name, "XHEL")
    }
    else if ((name == "stock_market" && (value != form.related_model_type) )) {
      getSecurities("10", value)

    }

    exchangeSetFieldValue(index, name, value)
  }

  const [securities, setSecurities] = useState(null)

  const handleFundChange = (index, event) => {
    setFundType(event.target.value)  
    getSecurities(form.related_model_type, form.stock_market,  {type:event.target.value})
} 
 
  const getSecurities = (type, stock_market, payload) => {
    setLoading(true)

    if (type === "20" || type === 20) {
      const params = {
        ordering: 'name',
        ...payload,
      }
      httpGetPrivateStocks(params).then(response => {
        // actions.setLoading(false)
        setSecurities(response?.data)

      }, error => {
        
      }).finally(() => {
        setLoading(false)
      })
    }
    else if (type === "30" || type === 30) {
      const params = {
        ordering: 'name',
        ...payload,
      }
      httpGetPrivateInvestmentFunds(params).then(response => {
        // setLoading(false)
        setSecurities(response?.data)
        if (response?.data.length > 0) {
          setCount(response?.data.length)
        }
      }, error => {
        
      }).finally(() => {
        setLoading(false)
      })
    }
    else if (type === "40" || type === 40) {
      const params = {
        ordering: 'name',
        ...payload,
      }
      httpGetInvestmentFunds(params).then(response => {
        // setLoading(false)
        setSecurities(response?.data)
        if (response?.data.length > 0) {
          setCount(response?.data.length)
        }
      }, error => {
        
      }).finally(() => {
        setLoading(false)
      })
    }
    else {  
      const params = {
        stock_market: stock_market,
        ordering: 'name',
        show_hidden: show_hided_basicdata,
        ...payload,
      }
      httpGetBasicData(params).then(response => {
        // actions.setLoading(false)
        setSecurities(response?.data)
        if (response?.data.length > 0) {
          setCount(response?.data.length)
        }
      }, error => {
        
      }).finally(() => {
        setLoading(false)
      })
    }
  }
  
  const getSecurity = useCallback(() => {
    return (
      <Row className="mb-3"> 
        <Col sm={12} lg={4} className="d-flex align-items-center">
        <label className="mb-1 pr-1 header">{t("security", "Arvopaperi")}</label>
        </Col>
        <Col className="mb-1">
        <StockSelect
        label=" "
        name="security"
        setFieldValue={setFieldValue}
        onChange={(event) => handleFormChange(index, event)}
        setFieldValueReturnId={false}
        ratesView={false}
        setValueToSelect={true} 
        objects={securities}
        updateFromStore={false}
        />
        </Col>
    </Row>
  )
  }, [securities, form.related_model_type, form.stock_market, fundType, loading, show_hided_basicdata])
  
  return (
    <>

        <Row className="mb-3"> 
            <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("investment_type_select_label", "Rahoitusinstrumentti")}</label>
            </Col>
            <Col className="mb-1">
            

            <InvestmentTypeSelect name="related_model_type" onChange={(event) => handleInvestmentTypeChange(index, event)} value={form.related_model_type} showAll={false} label=" "/>
            </Col>
        </Row>

        {
            form?.related_model_type === 30 || form?.related_model_type === "30" ? (
            <Row className="mb-3">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-1 pr-1 header">{t("sub_security_type", "tyyppi")}</label>
              </Col>
              <Col className="mb-1">
                <InvestmentTypeSelectSub name="fund_type" label=" " onChange={(event) =>handleFundChange(index, event)} value={fundType} showPrivate={false} showAll={false} icon="" />
              </Col>
            </Row>
            ) : null
          }
        {form?.stock_market != null && (
        <Row className="mb-3"> 
            <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("exchange_edit_stock_market", "Pörssi")}</label>
            </Col>
            <Col className="mb-1">
            <StockMarketSelect name="stock_market" onChange={(event) => handleStockMarketChange(index, event)} value={form.stock_market} showPrivate={false} showAll={false} icon="" label=" "/>
            </Col>
        </Row>
        )}

        {getSecurity()}
        {/* <Row className="mb-3" >
        <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-0 pr-1 header">{t("purchase_order_edit_currency", "Valuutta")}</label>
        </Col>
        <Col className="mb-1">
            <CurrencySelect 
            name='currency'
            value={form.currency}
            onChange={(event) => handleFormChange(index, event)}
            label="" 
            disabled={false}
            />
        </Col>
        </Row> */}
      {
        form?.related_model_type == 10 &&
        <Row>
        <Col sm={12} lg={4} className="d-flex align-items-center">
            
            </Col>
            <Col className="my-0">
            <div className="ml-0 mb-3">
              <Checkbox 
                id={"showHiddenBasicData" + index}
                name="show_hided_basicdata"
                checked={show_hided_basicdata}
                onChange={(event) =>  setShowHidedBasicData(!show_hided_basicdata)}
                value="show_hided_basicdata"
                className="zIndexZero"
                required
                label={<>{t("show_hided_basicdata", 'Näytä poistuneet')}</>}
              />
            </div>
            </Col>
          </Row>
      }
        <Row className="mb-3">
          <Col sm={12} lg={4} className="d-flex align-items-center">
          <label className="mb-0 pr-1 header">{t("exchange_new_quantity", "Uusi määrä")}</label>
          <HelpText contentKey="exchange_new_quantity" parentElementId="exchange_new_quantity" showButton={true}  className="mb-0"/>
          </Col>

          <Col sm={12} lg={4} >
          <Input
              name="sum_new_quantity"
              value={form.sum_new_quantity}
              onChange={(event) => handleFormChange(index, event)}
              label=""
              type="number"
              step="0.01"
              min={0}
              onWheel={(e) => e.target.blur()} // Prevents scrolling on input
              placeholder={t("0")}
          />
          </Col>
        </Row>  
        <Row className="mb-1">
          <Col sm={12} lg={4} className="d-flex align-items-center">
          <label className="mb-1 pr-1 header">{t("exchange_purchase_costs_percentage", "Menojen osuus %")}</label>
          </Col>
          <Col sm={12} lg={4} >
          <Input
              name="percentage"
              value={form.percentage}
              onChange={(event) => handleFormChange(index, event)}
              label=""
              type="number"
              step="0.01"
              min={0}
              onWheel={(e) => e.target.blur()} // Prevents scrolling on input
              placeholder={t("100")}
              
          />
          </Col>
        </Row>  
        <Row className="row justify-content-end">
        <Col lg={8} md={12} className="mb-4">
        <Typography variant="small">{t("exchange_purchase_costs_info_text","Kuinka paljon hank. kuluista siirretään uusille erille")}</Typography>
        </Col>
      </Row>       
    
        <div className="row justify-content-start">
        <div className="ml-3 mb-3">
            <Checkbox 
              id={"showExtraField" + index}
              name="showExtraField"
              checked={form?.showExtraField}
              onChange={(event) => handleFormChange(index, event)}
              value="showExtraField"
              className="zIndexZero"
              required
              label={<>{t("exchanges_add_purchase_cost", 'Lisää merkintähinta tai kuluja')}</>}
            />
          </div>
      </div>
        <>
        { (showExtraField || form.showExtraField) && 
          <>
          <Row className="mb-1">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("exchange_subscription_price_total", "Merkintähinta yht.")}</label>
              </Col>
              <Col sm={12} lg={4} >
              <Input
                  name="increase_value"
                  value={form.increase_value}
                  onChange={(event) => handleFormChange(index, event)}
                  label=""
                  type="number"
                  step="0.01"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("0")}
              />
              </Col>
          </Row>
          <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-2">
              <Typography variant="small">{t("exchange_subscription_price_total_info_text","Lunastettujen osakkeiden kpl määrä kerrottuna merkintähinnalla.")}</Typography>
            </Col>
          </Row>

          <Row className="mb-1 mt-2">
              <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("exchange_costs", "Kulut")}</label>
              </Col>
              <Col sm={12} lg={4} >
              <Input
                  name="increase_costs"
                  value={form.increase_costs}
                  onChange={(event) => handleFormChange(index, event)}
                  label=""
                  type="number"
                  step="0.01"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("0")}
              />
              </Col>
          </Row>
          </>
        }
        </>
        {/*
        <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("purchase_order_edit_ratio", "Suhdeluku")}</label>
            </Col>
            <Col sm={12} lg={4} >
            <Input
                name="ratio"
                value={form.ratio}
                onChange={(event) => handleFormChange(index, event)}
                label=""
                type="number"
                step="0.01"
                min={0}
                onWheel={(e) => e.target.blur()} // Prevents scrolling on input
                placeholder={t("esim. 0,5")}
            />
            </Col>
        </Row>
     
        <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("purchase_order_edit_capital_return", "Pääoman palautus ")}</label>
            </Col>
            <Col sm={12} lg={4} >
            <Input
                name="capital_return"
                value={form.capital_return}
                onChange={(event) => handleFormChange(index, event)}
                label=""
                type="number"
                step="0.01"
                min={0}
                onWheel={(e) => e.target.blur()}
                placeholder={t("0")}
            />
            </Col>
        </Row>




        <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
            <label className="mb-1 pr-1 header">{t("purchase_order_edit_comments", "Kommentit")}</label>
            </Col>
            <Col >
            <Input
                name="notes"
                value={form.notes}
                onChange={(event) => handleFormChange(index, event)}
                label=""
                placeholder={t("purchase_order_edit_comments_placeholder", "Kommentit")}
                type="textarea"
            />
            </Col>
        </Row>
        */}
        <Row className="my-1 mb-3">
            <Col  className="mr-auto text-left">
            <Button  variant="light" size="sm" onClick={() => handleFormRemove(index)}>{t("Poista")} </Button>
            </Col>
        </Row>
      
    </>
  );
}

export default ExchangeTo;



