import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAppContext }from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"


import { Row, Col, Container } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic, Popover } from "stories/components";
import { PageWrapper, PageHeader,  PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"
import LineChart from "stories/components/LineChart";
import { Pagination } from "stories/tables";
import { round,roundScaleDecimals, thousandFormat } from "services/helpers"
import InvestmentTypeCateSelect from "components/Forms/InvestmentTypeCateSelect/InvestmentTypeCateSelect"
import AllSecuritySelect from "components/Forms/AllSecuritySelect/AllSecuritySelect"
import FollowedSelect from "components/Forms/FollowedSelect/FollowedSelect"
import { httpGetAllSecurities, httpUpdateAccountingPrice } from "services/private_investment_funds"
import TradingViewWidget from "components/TradingViewWidget/TradingViewWidget"
import SidebarRight from "components/Sidebar/sidebarRight"; 
import StockSelect from "components/Forms/StockSelect/StockSelect"

import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"


import TradingViewWidgetSingleTicker from "components/TradingViewWidget/TradingViewWidgetSingleTicker"


import PrivateInvestmentFundEdit from "views/app/PrivateInvestmentFundEdit/PrivateInvestmentFundEdit"
import NewRecord from "views/app/NewRecord/NewRecord"
import { getDefaultOptions, set } from "date-fns";
import PasswordForgotView from "views/auth/PasswordForgot";

const PrivateInvestmentFundsList = ({match, history, location}) => {
  
  const { t } = useTranslation();
  const privateInvestmentFunds = useStoreState((state) => state.privateInvestmentFunds?.objects);
  // const loading = useStoreState((state) => state.privateInvestmentFunds?.loading);
  // const getPrivateInvestmentFunds = useStoreActions((actions) => actions.privateInvestmentFunds.getObjects);
  // const count = useStoreState((state) => state.privateInvestmentFunds?.count);
  const myAccount = useStoreState((state) => state.account?.account);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const portfoliosLoading = useStoreState((state) => state.portfolio?.loading);

  const {accountUserType} = useAuthContext()

  const { getSecurityTypeExtend } = useAppContext()

  const [securities, setSecurities] = useState()
  const [count, setCount] = useState()
  const [loading, setLoading] = useState(false)
  const [securityOptions, setSecurityOptions] = useState()
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [openSidebar, setOpenSidebar] = useState(false)
  const [symbolToWidget, setSymbolToWidget] = useState()
  const [stockMarketToWidget, setStockMarketToWidget] = useState()
  const [nameToWidget, setNameToWidget] = useState()
  const [fieldValue, setFieldValue] = useState()
  const [stockMarket, setStockMarket] = useState("");

  

  let path = location.pathname.split("/").pop()
  if (path === "funds") {
    path = "private_investment_fund"
  }
  if (path === "stocks") {
    path = "private_stock"
  }
  // get location latest /path from location
  useEffect(() => {
    let path = location.pathname.split("/").pop()
    if (path === "funds") {
      path = "private_investment_fund"
    }
    if (path === "stocks") {
      path = "private_stock"
    }

    setFilters(f => ({
      ...f,
      type: null,
    }))

    if (location?.state?.newSecurity) {
      openModalEdit(location?.state?.newSecurity)
    } 
  }, [location])

  useEffect(() => {
    setFilters(f => ({
      ...f,
      portfolio_batches_stock: portfolio?.id,
      followed_select: {key: "followed", label: t("followed_select_followed","Salkussa ja lisätyt")},


    }))
  }, [portfolio, portfoliosLoading])

  const getId = (id) => {
    return id?.split(",")[2]
  }
  const getRelatedModelType = (id) => {
    return id?.split(",")[0]
  }



  const [filters, setFilters] = useState({
    // limit: 30,
    custom_limit: 30,
    offset:0,
    custom_ordering: "name",
    // type: path,
    page: 0,
    extend_data: true,
    followed: true,
    all_securities: false,
    investmentType: {key: "all", label: t("Kaikki")},

  })
  
  useEffect(() => {
    // getPrivateInvestmentFunds(filters)
  }, [filters])

  // useEffect(() => {
  //   //if value is "all" then set rel
  //   let followed = false
  //   if (filters?.followed_select?.value === "all") {
  //     followed = true
  //   }
  //   setFilters(f => ({
  //     ...f,
  //     all_securities: followed,
  //   }))
  // }, [filters?.followed_select])
  
  const handleChange = (name, value) => {
    console.log("name", name, "value", value)
    if (name === "investmentType") {
      if (value.value === "all") {
        setFilters(f => ({
          ...f,
          related_model_type: null,
          id: null,
          type: null,
          [name]: value,
          all_securities_select: null,
          offset: 0,

        
        }))
      } 
      //check if value.key is number
      else if (typeof value.value === "number") {
        setFilters(f => ({
          ...f,
          related_model_type: value.value,
          id: null,
          type: value.value,
        [name]: value,
        all_securities_select: null,
        offset: 0,



        }))
      }
      else {
        setFilters(f => ({
          ...f,
          related_model_type: 30,
          type: value.value,
          id: null,
          all_securities_select: null,
          offset: 0,

          [name]: value,

        }))
      }
    }

    else {

      setFilters(f => ({
        ...f,
        [name]: value,
        related_model_type: filters?.investmentType?.key == "all" ? null : filters?.related_model_type,
        type: filters?.investmentType?.key == "all" ? null : filters?.type,
        id: null,
        //set opposite value for all_securities
        all_securities: value?.value === "all" ? true : false,
        // all_securities: null,
        all_securities_select: null,
        offset: 0,
        page: 0,
        custom_limit: 30,
      }))
    }
  }

  const handleChangeSecurity = (name, value) => {
    console.log("name", name, "value", value)
    let value_id = value?.value
    //separate related_model_type and related_model_id from value using "," first part is related_model_type and second is related_model_id
    //check if value is string
    if (typeof value_id === "string" && value_id !== "select") {
      const related_model_type = value_id?.split(",")[0]
      const type = value_id?.split(",")[1]
      const related_model_id = value_id?.split(",")[2]

      let inv_key = related_model_type
      if (inv_key == 30) {
        inv_key = type
      }
      

      setFilters(f => ({
        ...f,
        related_model_type: related_model_type,
        type: type,
        id: related_model_id,
        [name]: value,
        offset: 0,
        page: 0,
        custom_limit: 30,
        // investmentType: {key: inv_key },
        // all_securities: false,
      }))
    }
    else {


      setFilters(f => ({
        ...f,
        related_model_type: filters?.investmentType?.key == "all" ? null : filters?.related_model_type,
        type: null,
        id: null,
        [name]: value,
        offset: 0,
        page: 0,
        custom_limit: 30,
        // investmentType: {key: "all", label: "Kaikki"},
        // all_securities: false,
      }))
    }
  }





  const headers = [
    { label: t("PrivateInvestmentFundsList_type", "Laji"), key: "related_model_type"},
    { label: t("PrivateInvestmentFundsList_asd", "Nimi"), key: "name"},
    // { label: t("PrivateInvestmentFundsList_symbol", "Symbol"), key: "symbol"},
    { label: t("PrivateInvestmentFundsList_actions", "Arvonkehitys"), key: "actions"},
    { label: t("PrivateInvestmentFundsList_record", "Toiminnot"), key: "record"},
    { label: t("PrivateInvestmentFundsList_total_issue", "Osuuksia"), key: "total_issue"},
    
    { label: t("PrivateInvestmentFundsList_price", "Päätöshinta"), key: "latest_price"},
    { label: t("PrivateInvestmentFundsList_latest_price_date", "hinta pvm"), key: "latest_price_date"},
    { label: t("PrivateInvestmentFundsList_currency_label", "Currency"), key: "currency_label"},
    { label: t("PrivateInvestmentFundsList_split", "Viim split"), key: "split"},
    { label: t("PrivateInvestmentFundsList_dividend", "Viim osinko"), key: "dividend"},
    // { label: t("PrivateInvestmentFundsList_split", "LIVE"), key: "live"},
    // { label: t("PrivateInvestmentFundsList_split_date", "split pvm"), key: "split_date"},
    // { label: t("PrivateInvestmentFundsList_size", "Size"), key: "size"},
    // { label: t("PrivateInvestmentFundsList_datas_count", "Kirjauksia yht"), key: "datas_count"},
    // { label: t("PrivateInvestmentFundsList_seven_days", "Viimeisimmät 7 päivää"), key: "latest_seven_days"},
  ];

  const getAllSecurities = () => {
    let params = {
      ...filters,
      stock_market: stockMarket ? stockMarket : undefined,
      show_hidden: filters?.all_securities ? false : true,
    }
    if (params?.followed_select != null) {
    setLoading(true)
    
    httpGetAllSecurities(params).then(response => {
      setSecurities(response?.data?.results)
      setCount(response?.data?.count)
      setLoading(false)

    })}
  }

  const getAllSecuritiesOptions = () => {
    let params = {
      ...filters,
      stock_market: stockMarket ? stockMarket : undefined,
      id: null,
      extend_data: false,
      custom_limit: null,
      offset: null,
      page: null,
      related_model_type: filters?.investmentType?.key == "all" ? null : filters?.related_model_type,
      show_hidden: filters?.all_securities ? true : true,
    }
    if (params?.followed_select != null) {
    setLoadingOptions(true)
    
    httpGetAllSecurities(params).then(response => {
      setSecurityOptions(response?.data?.results)
      // setCount(response?.data?.count)
      setLoadingOptions(false)

    }, error => {
      setLoadingOptions(false)

    })}
  }

  useEffect(() => {
    getAllSecurities()
    getAllSecuritiesOptions()
    
    if (filters?.portfolio == portfolio?.id && portfoliosLoading == false ){

      getAllSecurities()
    }
  }, [filters, stockMarket])
  
  const getOptions = () => {
    //options for chartjs
    const options = {


    };
    return options
  }



  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.custom_limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
      page: filters?.page - 1,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.custom_limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
      page: filters?.page + 1,
    }))
  }

  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      // {name: t("Rahastot"), url: "/dashboard/"},
      {name: t(location.pathname), url: location.pathname},
    ]

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      const crumbLinks = [...location.state.crumbLinks] 
      crumbLinks.push({name: t(location.pathname), url: location.pathname})
      return crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  
  // set Link-element state to crumbLinks
  const setCrumbLinks = (links) => {
    history.push({
      pathname: location.pathname,
      state: {
        crumbLinks: links,
      }
    })
  }

  const getHeaders = () => {
    if (accountUserType === "read_only") {
      return headers.filter(header => header.key !== "record")
    }
    return headers
  }



  const getRowName = (row) => {
    return (
      <>
      {
        getRelatedModelType(row?.id) == 30 && 
        <Link className="" to={{pathname:"/dashboard/private_investment_funds/rates", state: {crumbLinks:getCrumbLinks(), fundId:getId(row.id), fundName: row?.name, currency: row?.currency_label, path: filters?.type, symbol:row?.symbol, stock_market:row?.stock_market}}}> 
          <span>
        
        <h4 className=" text-dark m-0">
        {row?.status == "all" ? "": <i className="fas fa-star text-secondary mr-1"></i>}{row?.name || "-"}
        </h4>
          </span>
        <Typography className="mt-0 text-dark" variant="small">{row?.status == "all" ? "":<i className="fas fa-star ml-2" style={{color:"transparent"}}></i>}{row?.symbol}{row?.stock_market && `:${row?.stock_market}`}</Typography>
        </Link>

        // <div  style={{cursor: 'pointer'}} onClick={()=> {
        //   setOpenSidebar(true)
        //   setSymbolToWidget(row?.symbol)
        //   setStockMarketToWidget(row?.stock_market)
        //   // setNameToWidget(row?.name)
        // }} > 
        // <span>

        // <h4 className=" text-dark m-0">
        // {row?.status == "all" ? "": <i className="fas fa-star text-secondary mr-1"></i>}{row?.name || "-"}
        // </h4>
        // </span>
        // <Typography className="mt-0 text-dark" variant="small">{row?.status == "all" ? "":<i className="fas fa-star ml-2" style={{color:"transparent"}}></i>}{row?.symbol}{row?.stock_market && `:${row?.stock_market}`}</Typography>
        // </div>
      }
      {
        getRelatedModelType(row?.id) == 10 &&
        <Link className="" to={{pathname:"/dashboard/private_investment_funds/stocks/rates/rate_list",search: `?stock_market=${row?.stock_market} `, state: {crumbLinks: getCrumbLinks(), stockId:getId(row.id),nasdaq_id: row?.nasdaq_id, stockName: row?.name,  stockCurrency:row?.currency_label?.content, symbol:row?.symbol, stock_market:row?.stock_market}}}>
        {/* <Link className="" to={{pathname:"/dashboard/private_investment_funds/rates", state: {crumbLinks:getCrumbLinks(), fundId:getId(row.id), fundName: row?.name, currency: row?.currency_label, path: filters?.type}}}> */}
        <span>
        
        <h4 className=" text-dark m-0">
        {row?.status == "all" ? "": <i className="fas fa-star text-secondary mr-1"></i>}{row?.name || "-"}
        </h4>
        </span>
        <Typography className="mt-0 text-dark" variant="small">{row?.status == "all" ? "":<i className="fas fa-star ml-2" style={{color:"transparent"}}></i>}{row?.symbol}{row?.stock_market && `:${row?.stock_market}`}</Typography>
        </Link>
        // <div  style={{cursor: 'pointer'}} onClick={()=> {
        //   setOpenSidebar(true)
        //   setSymbolToWidget(row?.symbol)
        //   setStockMarketToWidget(row?.stock_market)

        // }} > 
        // {/* {JSON.stringify(row?.name)} */}
        // {/* <Link className="" to={{pathname:"/dashboard/private_investment_funds/rates", state: {crumbLinks:getCrumbLinks(), fundId:getId(row.id), fundName: row?.name, currency: row?.currency_label, path: filters?.type}}}> */}
        // <span>
        
        // <h4 className=" text-dark m-0">
        // {row?.status == "all" ? "": <i className="fas fa-star text-secondary mr-1"></i>}{row?.name || "-"}
        // </h4>
        // </span>
        // <Typography className="mt-0 text-dark" variant="small">{row?.status == "all" ? "":<i className="fas fa-star ml-2" style={{color:"transparent"}}></i>}{row?.symbol}{row?.stock_market && `:${row?.stock_market}`}</Typography>
        // </div>
      }
      {
        getRelatedModelType(row?.id) == 40 &&
        <Link className="" to={{pathname:"/dashboard/private_investment_funds/investment_funds/rates/rate_list",search: `?stock_market=${filters?.stock_market} `, state: {crumbLinks: getCrumbLinks(), fundId:getId(row.id), fundName: row?.name, currency:row?.currency_label?.content}}}>
        {/* <Link className="" to={{pathname:"/dashboard/private_investment_funds/rates", state: {crumbLinks:getCrumbLinks(), fundId:getId(row.id), fundName: row?.name, currency: row?.currency_label, path: filters?.type}}}> */}
        <span>
        
        <h4 className=" text-dark m-0">
        {row?.status == "all" ? "": <i className="fas fa-star text-secondary mr-1"></i>}{row?.name || "-"}
        </h4>
        </span>
        <Typography className="mt-0 text-dark" variant="small">{row?.status == "all" ? "":<i className="fas fa-star ml-2" style={{color:"transparent"}}></i>}{row?.symbol}{row?.stock_market && `:${row?.stock_market}`}</Typography>
        </Link>
      }
      </>

    )
  }

  // <div className="d-flex justify-content-center" style={{disblay:"block", boxSizing: "border-box", width:"150px", height: "40px"}}>
  // <LineChart chartData={row.latest_data} options=""  />
  // </div>
  // number only get two decimals
  const twoDigits = (number) => {
    number = Number(number)
    return number && number.toFixed(2)
  }

  const openSidebarRight = (row) => {
    setOpenSidebar(true)
    setSymbolToWidget(row?.symbol)
    setStockMarketToWidget(row?.stock_market)
  }



  const getTableRows = useCallback(() => {
    if(securities){

      return securities && securities.map((row, index) => {
        //copy row
        let newRow = {...row}

        //set row 
        if (row?.latest_data?.[0]) {
          newRow.latest_price = ( <div className="d-flex justify-content-end pr-1">{thousandFormat(roundScaleDecimals(row.latest_data?.[0]?.updated_price,2))}</div>)
          newRow.latest_price_date = moment(row.latest_data?.[0]?.date).format('DD.MM.YYYY') || "-"
        } else {
          newRow.latest_price = " "
          newRow.latest_price_date = " "
        }
        // newRow.size = ( <div >{twoDigits(row.size)}</div> || "-")
        newRow.size = row?.size && {
          content: thousandFormat(roundScaleDecimals(row.size,2)),
          className: "text-right"
        }
        newRow.datas_count =  {
          content: thousandFormat(row.datas_count),
          className: "text-right"
        }
        let related_model_type_cell = {
          content: row?.related_model_type == 30 ? 
            <span className="text-center text-gray m-0" style={{maxWidth:"10px"}}>
              <div id={`type-${getId(row?.id)}`}><i className="fas fa-user-edit m-auto"></i></div>
              <Popover
              placement="top"
              target={`type-${getId(row?.id)}`}
            >       
              <Typography className="text-warning mb-1" variant="medium">
                 {t(getSecurityTypeExtend(row?.related_model_type, row?.type ).key, getSecurityTypeExtend(row?.related_model_type, row?.type ).value )}
              </Typography>  
            </Popover>
            </span> :
            <span className="text-center text-gray m-0" style={{maxWidth:"10px"}}>
              <div id={`type-${getId(row?.id)}`}><i className="fas fa-layer-group m-auto"></i></div>
              <Popover
              placement="top"
              target={`type-${getId(row?.id)}`}
            >       
              <Typography className="text-warning mb-1" variant="medium">
                  {t(getSecurityTypeExtend(row?.related_model_type, row?.type ).key, getSecurityTypeExtend(row?.related_model_type, row?.type ).value ) }
              </Typography>  
            </Popover>
            </span>
        }
  

        let nameCell = getRowName(row)

        newRow.latest_seven_days = (    
          <div className="d-flex justify-content-center">
          <Link to={{pathname:"/dashboard/private_investment_funds/rates", state: {crumbLinks:getCrumbLinks(), fundId:row.id, fundName: row?.name}}}>
            <div className="" style={{disblay:"block", boxSizing: "border-box", width:"150px", height: "40px"}}><LineChart chartData={row.latest_data} options={getOptions()} defaultStartDaysNum="7" /> </div> {t("")}
          </Link>
          </div>
        )      
        return {
          ...newRow,
          // name: nameCell,
          total_issue: Number(row?.total_issue) ? thousandFormat(row?.total_issue) : "-",
          related_model_type: related_model_type_cell,
          latest_price1: {
            content: row?.latest_stock_data?.updated_price || "-",
            className: "text-right mr-1"
          },
          latest_price: (
            <div className="d-flex justify-content-end">

            {myAccount?.show_relation_to_security && row?.latest_stock_data?.accounting_price_boolean ? (
              <>
                <i id={`connectedt-${getId(row?.id)}`} className="fas fa-archive text-primary mr-auto my-auto"></i>
                <Popover
                placement="left"
                target={`connectedt-${getId(row?.id)}`}
                > 
                <>
                <Typography className="text-dark mb-1" variant="medium">
                {t("private_investment_funds_list_accounting_text_1",'Kirjanpidossa ')}
                {row?.latest_stock_data?.accounting_price_date ? moment(row?.latest_stock_data?.accounting_price_date).format('DD.MM.YYYY') : "-"}
                {t("private_investment_funds_list_accounting_text_2",' asti')}

                </Typography>  
                </>

                </Popover>
            </>
              ):(
              <></>
              )
            }
            <span>

                {row?.latest_stock_data?.updated_price || "-"}
            </span>
              </div>
          ),
          currency_label: {
            content: row?.currency_label || "-",
            className: "text-center"
          },
          record: {
            content:(row?.related_model_type == 10 || row?.related_model_type == 30 ||  true ? <div className="d-flex justify-content-center"><Button className="btn-sm  font-weight-normal text-dark " color="default" onClick={() => openModalEditRecord(row)}><i className="fas fa-plus pr-1 "></i>{t("PrivateInvestmentFundsList_table_add_event","Toiminnot")}</Button></div> : <span className="text-gray">{t("privateInvestmentFund_action_placeholder","Ei toimintoja")}</span>),
            // className: "text-center"
          },
          latest_price_date: {
            content: row?.latest_stock_data?.date ? moment(row?.latest_stock_data?.date).format('DD.MM.YYYY') : "-",
            className: "text-right"
          },
          split: {
            content: row?.latest_split ? <span>({row?.latest_split?.dividend}) {moment(row?.latest_split?.date_effective).format('DD.MM.YYYY')}</span>  : " ",
            className: "text-right"
          },
          dividend: {
            content: row?.latest_dividend ? <span>({row?.latest_dividend?.rate}) {moment(row?.latest_dividend?.pay_date).format('DD.MM.YYYY')}</span>  : " ",
            className: "text-right"
          },
          live: {
            content: <div style={{height:"50px"}}><TradingViewWidgetSingleTicker stock_market= {row?.stock_market} symbol={row?.symbol} width="100%" height="100%" color="light"/></div>,
            className: "text-right"
          },

          actions: (
          <div className="d-flex justify-content-center">
            {/* {row?.related_model_type ==30 && <Button className="btn-sm ml-3" color="secondary" onClick={() => openModalEdit(getId(row?.id))}><i className="fas fa-edit"></i></Button> } */}
            {
              row?.related_model_type == 30 && 
            // <Link to={{pathname:"/dashboard/private_investment_funds/rates", state: {crumbLinks:getCrumbLinks(), fundId:getId(row.id), fundName: row?.name, currency: row?.currency_label,  path: filters?.type}}}>
            // </Link>
              <Button className="btn-sm font-weight-normal" color="default" onClick={() => openSidebarRight(row)}>
                <span className="font-weight-normal"></span> {t("Näytä")}
              </Button>
            }
            {
              row?.related_model_type == 10 && 
            // <Link to={{pathname:"/dashboard/private_investment_funds/stocks/rates/rate_list",search: `?stock_market=${row?.stock_market} `, state: {crumbLinks: getCrumbLinks(), stockId:getId(row.id),nasdaq_id: row?.nasdaq_id, stockName: row?.name,  stockCurrency:row?.currency_label, symbol:row?.symbol, stock_market:row?.stock_market}}}>
            // </Link>
              <Button className="btn-sm font-weight-normal" color="default" onClick={() => openSidebarRight(row)}>
                <span className="font-weight-normal"></span> {t("Näytä")}
              </Button>
            }



            {
              row?.related_model_type == 40 && 
            // <Link to={{pathname:"/dashboard/private_investment_funds/investment_funds/rates/rate_list",search: `?stock_market=${filters?.stock_market} `, state: {crumbLinks: getCrumbLinks(), fundId:getId(row.id), fundName: row?.name, currency:row?.currency_label}}}>
            // </Link>
              <Button className="btn-sm font-weight-normal" color="default" onClick={() => openSidebarRight(row)}>
                <span className="font-weight-normal"></span> {t("Näytä")}
              </Button>
            }
          </div>
          ),
        }
      })
    }
    else{
      return []
    } 

  }, [securities, filters])
  
  // Modal: New/Edit
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getAllSecurities()
    getAllSecuritiesOptions()
    // getPrivateInvestmentFunds(filters)
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  // Modal: NewRecord

  const [modalEditRecordOpen, setModalEditRecordOpen] = useState(false)
  const [modalEditRecordId, setModalEditRecordId] = useState()
  
  const toggleModalEditRecord = () => {
    setModalEditRecordOpen(s => !s);
  }
  
  const openModalEditRecord = (id) => {
    setModalEditRecordOpen(true)
    setModalEditRecordId(id);
  }
  
  const modalEditRecordResolve = () => {
    setModalEditRecordOpen(false);
    setModalEditRecordId(null);
    getAllSecurities()
    getAllSecuritiesOptions()
    // getPrivateInvestmentFunds(filters)
  }
  
  const modalEditRecordReject = () => {
    setModalEditRecordOpen(false);
    setModalEditId(null);
    getAllSecurities()

    }

  
  const getInvestmentTypeSelect = useCallback(() => {
    return <InvestmentTypeCateSelect name="investmentType" onChange={handleChange} showSubsOptions={true} setFieldValue={handleChange} value={filters.investmentType} label={t("investmentType_select_label",'Arvopaperi laji')} />
   }, [filters?.investmentType, myAccount])

  const getAllSecuritySelect = useCallback(() => {
    return (
    <>
    <AllSecuritySelect  style={{minWidth: '350px!important'}} name="all_securities_select" onChange={handleChangeSecurity} setFieldValue={handleChangeSecurity} value={filters?.all_securities_select} showAllOption={true} options={securityOptions} loadingOptions={loadingOptions}/>
    </>
    )
   }, [filters?.investmentType, securityOptions,loadingOptions, filters?.all_securities_select])


  
  const getSideBar = useCallback ( () =>{
    if (openSidebar && symbolToWidget){
      return (
        <SidebarRight
        open={openSidebar}
        setOpen={setOpenSidebar}
        symbol={symbolToWidget}
        stock_market={stockMarketToWidget}
        name={nameToWidget}
        />
        )
    }
    return(<></>)
  },[openSidebar, symbolToWidget, stockMarketToWidget, nameToWidget])


  const getStockSelect = useCallback(() => {

      return (
        <div name="stock_select_click">
        
        <StockSelect
          name="stock"
          label={t("purchase_order_edit_asset_stock-", "Arvopaperi *")}
          setFieldValue={setFieldValue}
          // setFieldTouched={setFieldTouched}
          // loading={stocksLoading}
          // setValues={setValues}
          setFieldValueReturnId={false}
          ratesView={false}
          setValueToSelect={true} 
          setIsSelectOpen={false}
          isSelectOpen={false}
          id="stock"
          async={true}
        />
        </div>
      )
    
  }, [ fieldValue?.stock  ])

  const handleStockMarketChange = ({target}) => {
    setStockMarket(target.value)
    // setFieldValue("stock", null)
    // setFieldValue("stock_private_obj", null)
  }

  const getStockMarketSelect = useCallback(() => {
    return (
      <StockMarketSelect name="stock_market" onChange={handleStockMarketChange} value={stockMarket} showPrivate={false} showAll={true} icon=""  />
    )
  }
  , [stockMarket])
 
  return (
    <div className="">
    <PageHeader title={t("PrivateInvestmentFundsList_title","Hallitse arvopapereita")} />
    <div className="py-1 pl-0 d-flex align-items-end ">
    <BreadcrumbsDynamic  links={getCrumbLinks()} />
    <Button className="ml-auto mb-2" color="secondary" disabled={accountUserType==="read_only"} onClick={() => openModalEdit(null)}>{t("PrivateInvestmentFundsList_new","Uusi arvopaperi")}</Button>

      </div>
       
    {/* {JSON.stringify(location)} */}
    {/* {JSON.stringify(getCrumbLinks())} */}
    {/* {JSON.stringify(filters)}
    {JSON.stringify(getSecurityTypeExtend(30, "index"))} */}
    <PageContainer>
      <div className="py-1 pl-0 d-flex ">
        {/* <Button className="ml-auto" color="secondary" onClick={() => openModalEdit(null)}>{t("PrivateInvestmentFundsList_new","Uusi arvopaperi")}</Button> */}
      {/*         
        {  !loading && (
          <div className="d-flex align-items-center ml-auto">
            <div className="mx-2" variant="h4">{t("PrivateInvestmentFundsList_count", "Näytä")}</div>
            <Pagination 
              paginationOffset={filters?.offset}
              paginationLimit={filters?.custom_limit}
              paginationCount={count}
              name={"custom_limit"}
              setFilters={setFilters}
              showPaginationCurrentPage={false}
              showPaginationNextAndPrevious={false}
              showVerticalBorders={false}
            />
            <div className="mx-2" variant="h4">{t("riviä")}</div>

          </div>
        )} */}
      </div>
      <Container fluid className="custom-no-padding  mt-0 m-0 mr-0 px-0" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>

            {/* <TradingViewWidget symbol="NASDAQ:TSLA" /> */}
        <Row className=" m-0 p-0 align-items-end">
          <Col sm="12" lg="9" className="m-0 p-0">
            <div className="d-flex flex-wrap ">
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '250px'}}>
                <div style={{ width: '100%' }}>
                  {myAccount?.license && getInvestmentTypeSelect()}
                </div>
              </div>

              {filters?.related_model_type==10 && 
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                {/* <StockMarketSelect name="stock_market" onChange={handleChange} value={filters?.stock}  showPrivate={false} showAll={true} icon=""/> */}
                {/* <StockMarketSelect name="stock_market" onChange={handleStockMarketChange} value={stockMarket} showPrivate={false} showAll={false} icon=""  /> */}
               {getStockMarketSelect()}
              </div>
              }
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <FollowedSelect name="followed_select" onChange={handleChange} setFieldValue={handleChange} value={filters?.followed_select} />
              </div>
              {/* <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-auto" style={{minWidth: '337px', maxWidth:'400px'}}> */}
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-auto" style={{ width: '100%', maxWidth:"400px" }}>
              <div style={{ width: '100%' }}>
                {getAllSecuritySelect()}
                </div>
              </div>
           
            </div>
          </Col>
          <Col sm="12"  lg="3"  className="px-0 ">
          <div className="d-flex  align-items-end py-2  " style={{minWidth: '150px'}}>
                {!loading && (
                  <div className="d-flex align-items-center ml-auto">
                    <div className="mx-2" variant="h4">{t("PrivateInvestmentFundsList_count", "Näytä")}</div>
                    <Pagination 
                      paginationOffset={filters?.offset}
                      paginationLimit={filters?.custom_limit}
                      paginationCount={count}
                      name={"custom_limit"}
                      setFilters={setFilters}
                      showPaginationCurrentPage={false}
                      showPaginationNextAndPrevious={false}
                      showVerticalBorders={false}
                    />
                    <div className="mx-2" variant="h4">{t("riviä")}</div>

                  </div>
                )}
              </div>
          </Col>
        </Row>
      </Container>

      {getSideBar()}
      <TableAuto
        showRowNumber={true}
        showId={false}
        checkboxes={false}
        headers={getHeaders()}
        headerColor={"light"}
        rows={getTableRows().map((row, index) => {row.name = getRowName(row); return row})}
        loading={loading}
        pagination={true}
        paginationLimitName={"custom_limit"}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.custom_limit}
        paginationCount={count}
        setFilters={setFilters}
      />
      </PageContainer>
      <Modal
          title={t("PrivateInvestmentFundEdit_title", "Oma arvopaperi")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
        <PrivateInvestmentFundEdit
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
        />
      </Modal>
      <Modal
          title={t("PrivateInvestmentFundEdit_record", "Uusi kirjaus")}
          isOpen={modalEditRecordOpen}
          toggleModal={toggleModalEditRecord}
          showHeader={false}
          classNameBody="p-0 padding-0"
          size="modal-lg"


        >
          {/* {JSON.stringify(modalEditRecordId)} */}
        <NewRecord
          objectId={modalEditRecordId}
          handleResolve={modalEditRecordResolve}
          handleReject={modalEditRecordReject}
          getAllSecurities= {getAllSecurities}
        />
      </Modal>

    </div>
  );
}

export default PrivateInvestmentFundsList;
